import React from "react";
import {BrowserRouter as Router, Switch, Route, Redirect} from "react-router-dom";
import Coupons from "./coupons/coupons";
import Referrals from "./referrals/referrals";
import Download from "./download/download";
import Payment from "./purchases/payment";
import Commission from "./commission/commission"
import Afiliates from "./affiliates/affiliates"
import CatalogoEducativo from "./catalogo/catalogoEducativo"

export default class App extends React.Component {
	render() {
		return (
			<Router>
				<Switch>
					<Route path="/coupons" component={Coupons}/>
					<Route path="/affiliates" component={Afiliates}/>
					<Route path="/referrals" component={Referrals}/>
					<Route path="/download" component={Download}/>
					<Route path="/payment" component={Payment}/>
					<Route path="/af" component={Commission}/>
					<Route path="/catalogoEducativo" component={CatalogoEducativo}/>

					<Redirect to="/coupons"/>
				</Switch>
			</Router>
		);
	}
}


