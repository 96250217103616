import React from "react";
import "../App.css";
import "../assets/cssPathbooks/stylePathbooks.css";
import "../assets/cssPathbooks/bootstrap.min.css";
import querystring from "query-string";
import moment from "moment";
import swal from "sweetalert2";
import {firestore, auth} from "../firebase/firebase";
import axios from "axios";

import ManualDescarga from "../download/downloadManual";
import MiniLanding from "../elements/miniLanding";

import logo from "../assets/images/logo.png";
import offertImg from "../assets/images/offers/ofertaEspecial.png";
import Helmet from "react-helmet";

import {PayPalButton} from "react-paypal-button-v2";

import en from "../language/en";
import es from "../language/es";

export default class commission extends React.Component {
	constructor(props) {
		super(props);
		const values = querystring.parse(this.props.location.search);
		this.state = {
			typeBoton: [],
			estatusForm: 1,
			userData: "",

			userName: "",
			email: "",
			password: "",
			paymentsPaypal: null,
			paymentsSubOfferPaypal: false,

			idSave: "",

			paymentTxtTitle: "",

			paypalAmount: 0,
			paypalDays: 0,

			paymentAmount: 0,
			paymentDays: 0,
			paymentColor: "#fff",
			idBoton: values.rid,
			paymentTxtTitleSubOffer: "",
			paymentAmountSubOffer: "",
			paymentDaysSubOffer: "",
			picAfiliado: "",
			name: "",
			emailCommission: "",
			divisaCommission: values.divi,

			contIntentoCompra: 0,
			contPagados: 0,
			contRegUsuarios: 0,
			contSubOffert: 0,

			srsDivisaCommission: "",

			txtPage: "",
		};
		this.changeManagement = this.changeManagement.bind(this);
	};

	async selectLanguage() {
		if (this.state.divisaCommission === "USD") {
			await this.setState({txtPage: en.commission});
		} else {
			await this.setState({txtPage: es.commission});
		}
	};

	async componentDidMount() {
		this.selectLanguage();
		await this.leerDatosOffer();

	}

	changeManagement(event) {
		const name = event.target.name;
		this.setState({
			[name]: event.target.value,
		});
	}


	createuser = () => {
		if (!this.state.userName) {
			swal.fire(this.state.txtPage.text29, this.state.txtPage.text30, "error");
		} else if (!this.state.email) {
			swal.fire(this.state.txtPage.text31, this.state.txtPage.text30, "error");
		} else if (!this.state.password) {
			swal.fire(this.state.txtPage.text32, this.state.txtPage.text30, "error");
		} else if (!this.state.email) {
			swal.fire(this.state.txtPage.text33, this.state.txtPage.text30, "error");
		} else {
			auth.createUserWithEmailAndPassword(this.state.email, this.state.password)
				.then(user => {
					this.setState({
						estatusForm: 2,
						userData: user,
					});
					this.contadorRegistroUsuarios();
				})
				.catch(error => {
					if (error.code === "auth/invalid-email") {
						swal.fire(this.state.txtPage.text33, this.state.txtPage.text34, "error");
					}
					if (error.code === "auth/email-already-in-use") {
						swal.fire(this.state.txtPage.text35, this.state.txtPage.text36, "error");
					}
					if (error.code === "auth/weak-password") {
						swal.fire(this.state.txtPage.text37, this.state.txtPage.text30, "error");
					}
				});
		}
	};

	enviaEmail = () => {
		const amount = this.state.paymentAmount;
		const urlSendMail = "https://us-central1-pathbook.cloudfunctions.net/sendEmailComisionista/";
		axios.get(urlSendMail, {
				params: {
					commission: this.state.name,
					userName: this.state.userName,
					amount: amount,
					divisa: this.state.divisaCommission,
					email: this.state.emailCommission,
					title: this.state.paymentTxtTitle,

				},
			})
			.then(function (response) {
				console.log(response);
			})
			.catch(function (error) {
				console.log(error);
			});
	};

	async guardaPagoPayPal() {
		///// jalar los datos
		var idUser = this.state.userData.user.uid;
		//// cadena a guardar
		await firestore.collection("users").doc(idUser).set({
			id: idUser,
			email: this.state.email,
			timestamp: moment().toDate(),
			username: this.state.userName,
			payments: null,
			paymentMethod: "PayPal",
		});
		const messageRef = await firestore.collection("users/" + idUser + "/payments").doc();
		await messageRef.set({payments: this.state.paymentsPaypal});
		this.guardaRecibo(messageRef.id);
	}

	async guardaPagoPayPalSub() {
		///// jalar los datos
		var idUser = this.state.userData.user.uid;
		const messageRef = await firestore.collection("users/" + idUser + "/payments").doc();
		await messageRef.set({payments: this.state.paymentsPaypal});
		this.guardaRecibo(messageRef.id);
	}

	async guardaRecibo(recibo) {
		var idUser = this.state.userData.user.uid;
		await firestore.collection("receipts").doc().set({
			expires: moment().add(this.state.paypalDays, "days").toDate(),
			id: this.state.idBoton,
			platform: "web",
			promo: false,
			receipt: firestore.doc("users/" + idUser + "/payments/" + recibo),
			sandbox: false,
			timestamp: moment().toDate(),
			token: null,
			user: firestore.doc("users/" + idUser),
			valid: true,
			paymentMethod: "PayPal",
			paypalAmount: this.state.paypalAmount,
			offert: this.state.paymentsSubOfferPaypal,
		});
	}


	miniOferta = async () => {
		await this.setState({
			paymentTxtTitleSubOffer: this.state.paymentTxtTitleSubOffer,
			paypalAmount: parseInt(this.state.paymentAmountSubOffer),
			paypalDays: parseInt(this.state.paymentDays) + parseInt(this.state.paymentDaysSubOffer),

			paymentsSubOfferPaypal: true,
		});
		await this.setState({estatusForm: 5});
	};

	miniRegresar = () => {
		this.setState({estatusForm: 3});
	};
	irPasoTres = () => {
		this.setState({estatusForm: 4});
	};

	headerOffer() {
		return (
			<div className="prising_header" style={{background: this.state.paymentColor}}>
				<div>
					<h5 className="subTituloBlanco texCenter">{this.state.paymenttxtHeader}</h5>
					<h1 className="tituloBlanco texCenter">{"$ " + this.state.paymentAmount + " " + this.state.divisaCommission}</h1>
					<h6 className="subTituloBlanco texCenter">{this.state.paymentTxtSubTitle}</h6>
				</div>
			</div>
		);
	}

	/////contadores de estadisticas
	async contadorPagados() {
		this.leerContadoresOffer();
		firestore.collection("affiliate").doc(this.state.idSave).update({
			contPagados: parseInt(this.state.contPagados + 1),
		});
	}

	async contadorPagadosSubOffer() {
		this.leerContadoresOffer();
		firestore.collection("affiliate").doc(this.state.idSave).update({
			contSubOffert: parseInt(this.state.contSubOffert + 1),
		});
	}

	async contadorRegistroUsuarios() {
		this.leerContadoresOffer();
		firestore.collection("affiliate").doc(this.state.idSave).update({
			contRegUsuarios: parseInt(this.state.contRegUsuarios + 1),
		});
	}


	leerContadoresOffer() {
		firestore.collection("affiliate").where("txtLink", "==", this.state.idBoton).get().then((documentSnapshot) => {
			documentSnapshot.docs.forEach((doc) => {
				this.setState({
					contPagados: doc.data().contPagados,
					contRegUsuarios: doc.data().contRegUsuarios,
					contSubOffert: doc.data().contSubOffert,
					contIniciados: doc.data().contIniciados,
				});
			});
		});
	}

	leerDatosOffer() {
		 firestore.collection("affiliate").where("txtLink", "==", this.state.idBoton).get().then( async (documentSnapshot)  => {
			documentSnapshot.docs.forEach((doc) => {

				this.setState({
					idSave: doc.id,
					paymentAmount: doc.data().amount,
					paymenttxtHeader: doc.data().txtHeader,
					paymentColor: doc.data().color,
					paymentDays: doc.data().days,
					paymentTxtSubTitle: doc.data().subTitle,
					paymentTxtTitle: doc.data().title,
					paymentTxtTitleSubOffer: doc.data().titleSubOffer,
					paymentAmountSubOffer: doc.data().amountSubOffer,
					paymentDaysSubOffer: doc.data().daysSubOffer,
					paypalAmount: doc.data().amount,
					paypalDays: doc.data().days,
					picAfiliado: doc.data().picAfiliado,
					name: doc.data().name,
					emailCommission: doc.data().email,
					divisaBDCommission: doc.data().divisa,
					contIniciados: doc.data().contIniciados
				});
			});
			await firestore.collection("affiliate").doc(this.state.idSave).update({
				contIniciados: parseInt(this.state.contIniciados + 1),
			});
		});
	}

	createCurencyPaypal = () => {
		const parent = this;
		let valuePay = 0;
		if (this.state.estatusForm === 2) {
			valuePay = this.state.paymentAmount;
		} else {
			valuePay = this.state.paymentAmountSubOffer;
		}
		return (
			<div>
				<PayPalButton
					createOrder={(data, actions) => {
						return actions.order.create({
							purchase_units: [{
								amount: {
									value: valuePay,
								},
							}],
						});
					}}
					onApprove={(data, actions) => {
						// Capture the funds from the transaction
						return actions.order.capture().then(function (details) {
							// Show a success message to your buyer
							swal.fire({
								title: "Pago realizado con exito " + details.payer.name.given_name + "!",
								text: "Continuar",
								icon: "success",
								confirmButtonText: "Cool",
							});
							if (parent.state.paymentsSubOfferPaypal === false) {
								parent.setState({
									estatusForm: 3,
									paymentsPaypal: details,
								});
								parent.guardaPagoPayPal();
								parent.enviaEmail();
								parent.contadorPagados();
							} else {
								parent.setState({
									estatusForm: 4,
									paymentsPaypal: details,
								});
								parent.guardaPagoPayPalSub();
								parent.contadorPagadosSubOffer();
							}
						});
					}}
				/>

			</div>
		);
	};

	render() {
		const variableDivisa = "https://www.paypal.com/sdk/js?client-id=AWgykr3hnzF0u2Xy1zwdtTt7SoJbqMN3eMMDGXzUg6ZW6xi6zzfAZslXpyaBbqsfYaqM7aB-3bBTRzb8&currency=" + this.state.divisaCommission;
		//const variableDivisa = "https://www.paypal.com/sdk/js?client-id=sb&currency=" + this.state.divisaCommission;
		return (
			<div className="body bodyDownload">
				<Helmet>
					<script src={variableDivisa} data-sdk-integration-source="button-factory"/>
				</Helmet>
				<div className="barraChica_area imagenBarraChica">
					<div className="">
						<div className="col-xl-12 col-md-12 centrarColumna">
							<img className="logoBarraChica" src={logo} alt=""/>
						</div>
					</div>
				</div>

				<div className="areaPrecios">
					<div className="col-xl-12 col-md-12 centrarColumna">
						<h2 className="tituloAzul texCenter ">{this.state.name}</h2>
						<div className="col-xl-2 col-md-2 centrarColumna">
							<img className="avatar" src={this.state.picAfiliado} alt={"userfoto"}/>
						</div>
						<br/>
						<br/>
						<h4 className="tituloGris texCenter">{this.state.paymentTxtTitle}</h4>
						<h4 className="tituloAzul texCenter ">{this.state.txtPage.text00}</h4>
						<br/>
					</div>
					<div className="container">
						<div className="single_prising  " data-wow-duration=".7s" data-wow-delay=".8s">

							{
								this.state.estatusForm === 1
									? (
										<div>
											{this.headerOffer()}
											<br/>
											<h3 className="tituloAzul texCenter">{this.state.txtPage.text03}</h3>
											<h5 className="subTituloNegro texCenter">{this.state.txtPage.text04}</h5>
											<div className="col-xl-8 col-md-6 centrarColumna">
												<br/>
												{/*<small id="emailHelp" className="form-text text-muted">Importante para poder darte una cuen.</small>*/}
												<div className="form-group">
													<label>{this.state.txtPage.text05}</label>
													<input value={this.state.userName} name="userName" placeholder="User Name" type="text" className="form-control" onChange={this.changeManagement}/>
												</div>
												<div className="form-group">
													<label htmlFor="exampleInputEmail1">{this.state.txtPage.text06}</label>
													<input value={this.state.email} name="email" placeholder="Email" type="email" className="form-control" onChange={this.changeManagement}/>
												</div>
												<div className="form-group">
													<label htmlFor="exampleInputEmail1">{this.state.txtPage.text07}</label>
													<input value={this.state.password} name="password" placeholder="Password" type="password" className="form-control" onChange={this.changeManagement}/>
												</div>

												<div className="col col-md-8 centrarColumna">
													<br/>
													<button className="botonCompraAhora" onClick={this.createuser}>{this.state.txtPage.text08}</button>
												</div>
											</div>

										</div>
									)
									: null
							}


							{
								this.state.estatusForm === 2
									? (
										<div>
											{this.headerOffer()}
											<br/>
											<h3 className="tituloAzul texCenter">{this.state.txtPage.text01}</h3>
											<h5 className="subTituloNegro texCenter">{this.state.txtPage.text02}</h5>
											<div className="col-xl-8 col-md-8 centrarColumna">
												<br/>
												<div className="col-xl-8 col-md-8 centrarColumna">
													{this.createCurencyPaypal()}

												</div>
											</div>
										</div>
									)
									: null
							}
							{
								this.state.estatusForm === 3
									? (
										<div>
											<div className="prising_header" style={{background: this.state.paymentColor}}>
												<div>
													<h3 className="tituloBlanco texCenter">{this.state.userName + " gracias por tu compra"}</h3>
													<h5 className="subTituloBlanco texCenter">{this.state.txtPage.text09}</h5>
												</div>
											</div>
											<br/>

											<div className="col-xl-10 col-md-10 centrarColumna">
												<div className="row">
													<div className="col col-md-5">
														<br/>
														<img className="imgOffert " src={offertImg} alt=""/>
														<br/>
														<h4 className=" subTituloNegro texCenter">{this.state.txtPage.text10}</h4>
													</div>
													<div className="col col-md-7">
														<br/>
														<br/>
														<h5 className="subTituloNegro texCenter">{this.state.paymentTxtTitleSubOffer}</h5>
														<h2 className="subTituloNegro texCenter">{this.state.txtPage.text11}<b className="tituloAzul">{this.state.paymentAmountSubOffer + " " + this.state.divisaCommission}</b></h2>
														<h4 className=" subTituloNegro texCenter">{this.state.txtPage.text12}</h4>
														<br/>
														<button className="aprobechaAhora " onClick={this.miniOferta}>{this.state.txtPage.text13}</button>
														<br/>
														<br/>
														<h6 className=" subTituloNegro texCenter">{this.state.txtPage.text14}</h6>
														<button className="botonCompraAhora" onClick={this.irPasoTres}>{this.state.txtPage.text15}</button>
													</div>
												</div>
											</div>
										</div>
									)
									: null
							}
							{
								this.state.estatusForm === 4
									? (
										<div>
											<div className="prising_header" style={{background: this.state.paymentColor}}>
												<div>
													<h3 className="tituloBlanco texCenter">{this.state.userName + " gracias por tu compra"}</h3>
													<h5 className="subTituloBlanco texCenter">{this.state.txtPage.text16}</h5>
													{
														this.state.paymentsSubOfferPaypal === false
															? (
																<div className="col-xl-6 col-md-6 centrarColumna">
																	<br/>
																	<button className="bttnOferta " onClick={this.miniOferta}>{this.state.txtPage.text17}</button>
																</div>
															)
															: null
													}
												</div>
											</div>
											<br/>
											<div className="col-xl-10 col-md-10 centrarColumna">
												<h3 className="tituloAzul texCenter">{this.state.txtPage.text18}</h3>
												<br/>
												<ManualDescarga divisa={this.state.divisaCommission}/>
											</div>
										</div>
									)
									: null
							}
							{
								this.state.estatusForm === 5
									? (
										<div>
											<div className="prising_header" style={{background: this.state.paymentColor}}>
												<div>
													<h3 className="tituloBlanco texCenter">{this.state.userName + " gracias por tu compra"}</h3>
													<h5 className="subTituloBlanco texCenter">{this.state.txtPage.text19}</h5>
												</div>
											</div>
											<br/>
											<div className="container col-xl-10 col-md-10 centrarColumna">
												<div className="row">
													<div className="col-xl-8 col-md-8 centrarColumna">
														<br/>
														<h1 className="subTituloNegro texCenter">{this.state.txtPage.text20}<b className="tituloAzul">{this.state.paymentAmountSubOffer + " " + this.state.divisaCommission}</b></h1>
														<h4 className="tituloNegro texCenter">{this.state.paymentTxtTitleSubOffer}</h4>
														<br/>
														<h6 className="subTituloNegro texCenter">{this.state.txtPage.text21}</h6>
														<br/>
														<div className="col-xl-8 col-md-8 centrarColumna">
															{this.createCurencyPaypal()}
														</div>
													</div>
													<div className="col-xl-5 col-md-5 centrarColumna">
														<br/>
														<button className="botonCompraAhora" onClick={this.miniRegresar}>{this.state.txtPage.text28}</button>
													</div>
												</div>
											</div>
										</div>
									)
									: null
							}
						</div>
					</div>
					{
						this.state.estatusForm === 1
							? (
								<MiniLanding divisa={this.state.divisaCommission}/>
							)
							: null
					}
				</div>

			</div>
		);
	}
}
