const en = {
	affiliates: {
		text01: "Thank you for registering in our affiliate system",
		text01a: "Affiliate module",
		text02: "This is the time to generate your affiliate link so you need to fill in the following information",
		text03: "We recommend that you have the following information on hand as well as a photograph for your profile",
		text04: "Already have an account? ",
		text05: "Log in by click here",
		text06: "This section is for new users.",
		text07: "Iniciar sesión",
		text08: "¿No tienes una cuenta?",
		text09: "¿Quieres registrarte?",
		text10: "haz clic aquí",
		text11: "Ingresa tu código",
		text12: "Hola !",
		text13: "Cerrar sesión",
		text14: "Cobrado correctamente",
		text15: "Tu cupón fue agregado a tu cuenta de manera exitosa",
		text16: "Ahora solo tienes que descargar e iniciar sesión en Pathbooks",
		text17: "Sigue esta guía para continuar",
		text18: "Salir",
		text19: "The id has to be a minimum of 6 characters maximum of 10 without spaces or strange characters",
		text20: "",
		impText01: "Nombre",
		impText02: "Correo electrónico",
		impText03: "Crear contraseña",
		impText04: "Confirmar contraseña",
		impText05: "Iniciar sesión",
		impText06: "Contraseña",
		impText07: "Ingrese su código",
		impText08: "Enter a user id",
		impText09: "Enter your phone number",
		txtFooter01: "Remember when filling this data:",
		txtFooter02: "* This registration is totally free",
		txtFooter03: "* You will generate a link for an offer of $ 299.00 Mexican pesos for your clients",
		txtFooter04: "* The commission of each sale is 25%",
		txtFooter05: "* You can only withdraw the money through PayPal",
		txtFooter06: "* You need to be of legal age to be an affiliate candidate",
		txtFooter07: "* If the requirements are not met, you will not be able to withdraw the money generated",
		txtFooter08: "* If you have questions contact support:",

		textBtn01: "Crear cuenta",
		textBtn02: "Continuar con Facebook",
		textBtn03: "Aplicar código",
		alertTxt01: "¡Éxito!",
		alertTxt02: "¡Ups!",
		alertTxt03: "Las contraseñas no coinciden",
		alertTxt04: "Esta cuenta fue creada por un código",
		alertTxt05: "El código se aplicó correctamente",
	},
	coupons: {
		text01: "Stories for everyone, have a look and live a new experience",
		text02: "Code activation screen",
		text03: "Create an Account",
		text04: "Already have an account? ",
		text05: "Click here",
		text06: "This section is for new users.",
		text07: "Login",
		text08: "Don't you have an account?",
		text09: "do you want to register?",
		text10: "click here",
		text11: "Enter your code",
		text12: "Hi !",
		text13: "Sign out",
		text14: "Charged correctly",
		text15: "Your coupon was added to your account successfully",
		text16: "Now you just have to download and log into Pathbooks",
		text17: "Follow this guide to continue",
		text18: "Exit",
		impText01: "Your name",
		impText02: "Your email",
		impText03: "Create password",
		impText04: "Confirm password",
		impText05: "Login",
		impText06: "Your password",
		impText07: "Enter your code",
		textBtn01: "Create account",
		textBtn02: "Continue with Facebook",
		textBtn03: "Apply code",
		alertTxt01: "Success!",
		alertTxt02: "Oops!",
		alertTxt03: "Passwords do not match",
		alertTxt04: "This account was created by a Code",
		alertTxt05: "Code applied successfully",
	},
	referrals: {
		title01a: "gives you",
		title01b: "We give you",
		title01: "a coupon for you to enjoy PATHBOOKS for 7 days free.",
		title02: "Just follow the steps below to be able to redeem your prize",
		title03: "Step 1 - Register your user",
		title04: "Step 2 - Download PATHBOOKS",
		title05: "Step 3 - Inside the app",
		impText01: "Your name",
		impText02: "Your e-mail",
		impText03: "Create your password",
		impText04: "Confirm your password",
		btnText01: "Create Account",
		btnText02: "Continue with Facebook",
		btnText03: "Español",
		text01: "Download for iOS",
		text02: "Download for Android",
		text03: "Once you see the app, look for the configuration button, it is located at the bottom of the right side, log in with the same data that you registered.",
		text04: "© Copyright Pathbooks",
		text05: "Download now",
		alert: "Ok",
		alertA01: "Oops",
		alertB01: "Passwords don't match",
		alertA02: "Add email",
		alertB02: "Empty add an email to continue",
	},
	payment: {
		text01: "Thousands of people around the world already enjoy Pathbooks",
		text02: "In 3 simple steps you can have great benefits",
		text03: "Use the payment method of your choice",
		text04: "Step 1",
		text05: "Use the payment method of your choice",
		text06: "Step 2",
		text07: "Fill the user data",
		text08: "Username",
		text09: "Enter an email",
		text10: "Enter a password",
		text11: "Continue",
		text12: "First user",
		text13: "Primary User",
		text14: "Second user",
		text15: "Secondary user",
		text16: "Register and continue",
		text17: "In gratitude we have for you a super offer",
		text18: "Available only at this time",
		text19: "Enjoy much longer",
		text20: "Seize now",
		text21: "Now no thanks",
		text22: "Go to step 3",
		text23: "Step 3",
		text24: "View deal",
		text25: "Use the payment method of your choice",
		text26: "To return",
		text27: "For only"
	},
	download: {
		text01: "Download the app and log in",
		text02: "iOS Users",
		text03: "If you use an iOS device download at:",
		text04: "Android users",
		text05: "If you use an Android device download at:",
		text06: "With the username and password that you registered in step two",
		text07: "Find the button ",
		text08: "configuration",
		text09: " at the bottom as shown in the image",
		text10: "Press the button that says ",
		text11: "Login / Create account",
		text12: "Press the button ",
		text13: "login",
		text14: " and entered the user data from step 2",
		text15: "Start enjoying an incredible reading experience",
	},
	commission: {
		text00: "In 3 simple steps you can have great benefits",
		text01: "Step 2",
		text02: "Use the payment method of your choice",
		text03: "Step 1",
		text04: "Fill the user data",
		text05: "Username",
		text06: "Enter an email",
		text07: "Enter a password",
		text08: "Continue",
		text09: "In gratitude we have for you a super offer.",
		text10: "Available only at this time",
		text11: "For only $",
		text12: "Enjoy much longer.",
		text13: "Take advantage now",
		text14: "Now no thanks",
		text15: "Go to step 3",
		text16: "In gratitude we have a super offer for you",
		text17: "See offer",
		text18: "Step 3",
		text19: "In gratitude we have for you a super offer.",
		text20: "For only $",
		text21: "Use the payment method of your choice.",
		text22: "Payment made successfully",
		text23: "Continue",
		text28: "Go back",
		text29: "Please enter a username",
		text30: "Thank you",
		text31: "Enter an Email",
		text32: "The password must be at least 6 characters",
		text33: "Invalid email",
		text34: "Please enter a correct email",
		text35: "The email address is already in use",
		text36: "Enter a different email",
		text37: "The password must be at least 6 characters",
	},
	miniLnading: {
		text00: "Pathbooks",
		text01: " is the first interactive reading platform",
		text02: "Pathbooks is reinventing the concept of reading. If you didn't like the ending of the last book you read, you now have the power to change the course of history and come up with unique plots based on your decisions. Unique, immersive and intense Who said reading was boring? ",
		text03: "We have a great collection",
		text04: "In addition to mode",
		text05: "childish",
		text06: "This offer gives you the great benefits of",
		text07: "Pathbooks",
		text08: "Pro",
		text09: "Unlimited pathbooks",
		text10: "You will have all the content available to read, in addition to audiobooks, series and improvements to our application",
		text11: "Offline mode",
		text12: "You can download your favorite content so that you never miss out on reading, you decide which books to download and so your favorite reading will go with you",
		text13: "Synchronization between devices",
		text14: "All your devices will be synchronized so you can read the one you want and change when you like",
		text15: "New content weekly",
		text16: "A huge community of super talented writers are preparing new content so you always have something new to read",
		text17: "Now get full access to the largest library of",
		text18: "interactive content",
		text19: "We make reading be",
		text20: "accessible, interactive and fun",
		text21: "we currently offer options in English and Spanish",
		text22: "You can find",
		text23: " many works",
		text28: " created by people around the world",
	},
};

export default en;
