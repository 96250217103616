import React from "react";
import logo from "../assets/images/logo.png";
import Preloader from "../preloader";
import swal from "sweetalert2";
import {firestore} from "../firebase/firebase";
import "../App.css";


export default class CatalogoEducativo extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			mode: "signup",
			listaPathbooksA: [],
			listaPathbooksB: [],
			listaPathbooksB15: [],
			estatusTodos: false,
			estatusPrimaria: false,
			estatusSecundaria: false,
			estatusPreparatoria: false,
			estatusPathbooks: 0,
		};
		this.manejadorImputs = this.manejadorImputs.bind(this);
	}

	manejadorImputs(event) {
		const target = event.target;
		const value = target.type === "checkbox" ? target.checked : target.value;
		const name = target.name;


		if (name === "estatusTodos") {
			this.setState({
				estatusPathbooks: 0,
			});
		}
		if (name === "estatusPrimaria") {
			this.setState({
				estatusPathbooks: 1,
			});
		}
		if (name === "estatusSecundaria") {
			this.setState({
				estatusPathbooks: 2,
			});
		}
		if (name === "estatusPreparatoria") {
			this.setState({
				estatusPathbooks: 3,
			});
		}
	}

	aletaPathbook(pathbook) {
		swal.fire({
			title: "<strong>" + pathbook.title["es"] + " </strong>",
			html:
				"<p> Clasificación: <b>" + pathbook.classification + "</b></p>" +
				"<p> Tipo: <b>" + pathbook.type + "</b></p>" +
				"<p><b>" + pathbook.summary["es"] + "</b></p>" +
				"<br/>" +
				"<img src=" + pathbook.cover["es"] + " alt=\"portada\" width=\"300\"/> ",
			focusConfirm: false,
			confirmButtonText: "Regresar",
		});
	}

	async componentDidMount() {
		let listaPathbooksA = [];
		let listaPathbooksB = [];
		let listaPathbooksB15 = [];
		await firestore.collection("pathbooks").onSnapshot(querySnapshot => {
			listaPathbooksA = [];
			let consultaPathbooks = querySnapshot.docs;
			consultaPathbooks.forEach((doc) => {
				if (doc.data().active === true) {
					if (doc.data().classification === "AA" || doc.data().classification === "A") {
						listaPathbooksA.push({
							idPAthbooks: doc.id,
							pathbook: doc.data(),
						});
					}
					if (doc.data().classification === "B") {
						listaPathbooksB.push({
							idPAthbooks: doc.id,
							pathbook: doc.data(),
						});
					}
					if (doc.data().classification === "B15") {
						listaPathbooksB15.push({
							idPAthbooks: doc.id,
							pathbook: doc.data(),
						});
					}

				}

			});
			this.setState({
				listaPathbooksA: listaPathbooksA,
				listaPathbooksB: listaPathbooksB,
				listaPathbooksB15: listaPathbooksB15,
			});
		});
	}


	render() {
		return (
			<div className="body">
				<div className="barraChica_area imagenBarraChica">
					<div className="container">
						<div className="row ">
							<img className="logoBarraChica" src={logo} alt=""/>
						</div>
						<br/>
						<h3 className="tituloBlanco texCenter">Catalogo Educativo</h3>
					</div>
				</div>
				<section id="contact" className="areaPrecios">
					<div className=" px-md-3">
						<div className=" text-center wow" data-wow-duration=".7s" data-wow-delay=".8s">
							<div className="btn-group btn-group-toggle">
								<label className="btn btn-secondary">
									<input type="radio" name="estatusTodos" id="option1" autoComplete="off" checked={this.state.estatusPrimaria} onChange={this.manejadorImputs}/> Todos los Pathbooks
								</label>
								<label className="btn btn-secondary">
									<input type="radio" name="estatusPrimaria" id="option2" autoComplete="off" checked={this.state.estatusPrimaria} onChange={this.manejadorImputs}/> Primaria
								</label>
								<label className="btn btn-secondary">
									<input type="radio" name="estatusSecundaria" id="option3" autoComplete="off" checked={this.state.estatusSecundaria} onChange={this.manejadorImputs}/> Secundaria
								</label>
								<label className="btn btn-secondary">
									<input type="radio" name="estatusPreparatoria" id="option3" autoComplete="off" checked={this.state.estatusPreparatoria} onChange={this.manejadorImputs}/> Preparatoria
								</label>
							</div>
						</div>
					</div>
					<div className="areaPrecios px-md-3">
						<div className="single_prising text-center wow" data-wow-duration=".7s" data-wow-delay=".8s">
							{
								this.state.estatusPathbooks === 0
									? (
										<div>
											<div className="prising_header" style={{background: "#144469"}}>
												<h2 className="subTituloBlanco texCenter">Catalogo educativo</h2>
												{/*<h4 className="subTituloBlanco texCenter">Cantidad de pathbooks: {this.state.listaPathbooksA.length + this.state.listaPathbooksB.length + this.state.listaPathbooksB15.length}</h4>*/}
											</div>
											<div className="row col ">
												{
													this.state.listaPathbooksA.map(task =>
														<div className="col-sm-4 cardPathbook" key={task.idPAthbooks}>
															<br/>
															<div className="card">
																<div className="card-body">
																	<div className="row">
																		<div className="col-md-4">
																			<img src={task.pathbook.cover["es"]} alt="feature" width="100"/>
																		</div>
																		<div className="col-md-8">
																			<br/>
																			<h6 className="card-title">{task.pathbook.title["es"]}</h6>
																			<p className="card-text">{task.pathbook.title["en"]}</p>
																			<p className="card-text">Calsificacion: {task.pathbook.classification}</p>
																			<button className="full-width botonCompraAhora" type="button" onClick={() => this.aletaPathbook(task.pathbook)}>Ver mas</button>
																		</div>
																	</div>
																</div>
															</div>
														</div>,
													)}
												{
													this.state.listaPathbooksB.map(task =>
														<div className="col-sm-4" key={task.idPAthbooks}>
															<div className="card">
																<div className="card-body">
																	<div className="row">
																		<div className="col-md-4">
																			<img src={task.pathbook.cover["es"]} alt="feature" width="100"/>
																		</div>
																		<div className="col-md-8">
																			<h6 className="card-title">{task.pathbook.title["es"]}</h6>
																			<p className="card-text">{task.pathbook.title["en"]}</p>
																			<p className="card-text">Calsificacion: {task.pathbook.classification}</p>
																			<button className="full-width botonCompraAhora" type="button" onClick={() => this.aletaPathbook(task.pathbook)}>Ver mas</button>
																		</div>
																	</div>
																</div>
															</div>

														</div>,
													)}
												{
													this.state.listaPathbooksB15.map(task =>
														<div className="col-sm-4" key={task.idPAthbooks}>
															<div className="card">
																<div className="card-body">
																	<div className="row">
																		<div className="col-md-4">
																			<img src={task.pathbook.cover["es"]} alt="feature" width="100"/>
																		</div>
																		<div className="col-md-8">
																			<h6 className="card-title">{task.pathbook.title["es"]}</h6>
																			<p className="card-text">{task.pathbook.title["en"]}</p>
																			<p className="card-text">Calsificacion: {task.pathbook.classification}</p>
																			<button className="full-width botonCompraAhora" type="button" onClick={() => this.aletaPathbook(task.pathbook)}>Ver mas</button>
																		</div>
																	</div>
																</div>
															</div>

														</div>,
													)}
											</div>
										</div>
									)
									:
									null
							}
							{
								this.state.estatusPathbooks === 1
									? (
										<div>
											<div className="prising_header" style={{background: "#144469"}}>
												<h2 className="subTituloBlanco texCenter">Catalogo educativo Primaria</h2>
												{/*<h4 className="subTituloBlanco texCenter">Cantidad de pathbooks: {this.state.listaPathbooksA.length}</h4>*/}
											</div>
											<div className="row col ">
												{
													this.state.listaPathbooksA.map(task =>
														<div className="col-sm-4 cardPathbook" key={task.idPAthbooks}>
															<br/>
															<div className="card">
																<div className="card-body">
																	<div className="row">
																		<div className="col-md-4">
																			<img src={task.pathbook.cover["es"]} alt="feature" width="100"/>
																		</div>
																		<div className="col-md-8">
																			<br/>
																			<h6 className="card-title">{task.pathbook.title["es"]}</h6>
																			<p className="card-text">{task.pathbook.title["en"]}</p>
																			<p className="card-text">Calsificacion: {task.pathbook.classification}</p>
																			<button className="full-width botonCompraAhora" type="button" onClick={() => this.aletaPathbook(task.pathbook)}>Ver mas</button>
																		</div>
																	</div>
																</div>
															</div>
														</div>,
													)}

											</div>
										</div>
									)
									:
									null
							}
							{
								this.state.estatusPathbooks === 2
									? (
										<div>
											<div className="prising_header" style={{background: "#144469"}}>
												<h2 className="subTituloBlanco texCenter">Catalogo educativo Secundaria</h2>
												{/*<h4 className="subTituloBlanco texCenter">Cantidad de pathbooks: {this.state.listaPathbooksB.length + this.state.listaPathbooksA.length}</h4>*/}
											</div>
											<div className="row ">
												{
													this.state.listaPathbooksB.map(task =>
														<div className="col-sm-4" key={task.idPAthbooks}>
															<div className="card">
																<div className="card-body">
																	<div className="row">
																		<div className="col-md-4">
																			<img src={task.pathbook.cover["es"]} alt="feature" width="100"/>
																		</div>
																		<div className="col-md-8">
																			<h6 className="card-title">{task.pathbook.title["es"]}</h6>
																			<p className="card-text">{task.pathbook.title["en"]}</p>
																			<p className="card-text">Calsificacion: {task.pathbook.classification}</p>
																			<button className="full-width botonCompraAhora" type="button" onClick={() => this.aletaPathbook(task.pathbook)}>Ver mas</button>
																		</div>
																	</div>
																</div>
															</div>

														</div>,
													)}
												{
													this.state.listaPathbooksA.map(task =>
														<div className="col-sm-4 cardPathbook" key={task.idPAthbooks}>
															<br/>
															<div className="card">
																<div className="card-body">
																	<div className="row">
																		<div className="col-md-4">
																			<img src={task.pathbook.cover["es"]} alt="feature" width="100"/>
																		</div>
																		<div className="col-md-8">
																			<br/>
																			<h6 className="card-title">{task.pathbook.title["es"]}</h6>
																			<p className="card-text">{task.pathbook.title["en"]}</p>
																			<p className="card-text">Calsificacion: {task.pathbook.classification}</p>
																			<button className="full-width botonCompraAhora" type="button" onClick={() => this.aletaPathbook(task.pathbook)}>Ver mas</button>
																		</div>
																	</div>
																</div>
															</div>
														</div>,
													)}
											</div>
										</div>
									)
									:
									null
							}
							{
								this.state.estatusPathbooks === 3
									? (
										<div>
											<div className="prising_header" style={{background: "#144469"}}>
												<h2 className="subTituloBlanco texCenter">Catalogo educativo Preparatoria</h2>
												{/*<h4 className="subTituloBlanco texCenter">Cantidad de pathbooks: {this.state.listaPathbooksB15.length + this.state.listaPathbooksB.length + this.state.listaPathbooksA.length}</h4>*/}
											</div>
											<div className="row ">
												{
													this.state.listaPathbooksB15.map(task =>
														<div className="col-sm-4" key={task.idPAthbooks}>
															<div className="card">
																<div className="card-body">
																	<div className="row">
																		<div className="col-md-4">
																			<img src={task.pathbook.cover["es"]} alt="feature" width="100"/>
																		</div>
																		<div className="col-md-8">
																			<h6 className="card-title">{task.pathbook.title["es"]}</h6>
																			<p className="card-text">{task.pathbook.title["en"]}</p>
																			<p className="card-text">Calsificacion: {task.pathbook.classification}</p>
																			<button className="full-width botonCompraAhora" type="button" onClick={() => this.aletaPathbook(task.pathbook)}>Ver mas</button>
																		</div>
																	</div>
																</div>
															</div>

														</div>,
													)}
												{
													this.state.listaPathbooksB.map(task =>
														<div className="col-sm-4" key={task.idPAthbooks}>
															<div className="card">
																<div className="card-body">
																	<div className="row">
																		<div className="col-md-4">
																			<img src={task.pathbook.cover["es"]} alt="feature" width="100"/>
																		</div>
																		<div className="col-md-8">
																			<h6 className="card-title">{task.pathbook.title["es"]}</h6>
																			<p className="card-text">{task.pathbook.title["en"]}</p>
																			<p className="card-text">Calsificacion: {task.pathbook.classification}</p>
																			<button className="full-width botonCompraAhora" type="button" onClick={() => this.aletaPathbook(task.pathbook)}>Ver mas</button>
																		</div>
																	</div>
																</div>
															</div>

														</div>,
													)}
												{
													this.state.listaPathbooksA.map(task =>
														<div className="col-sm-4 cardPathbook" key={task.idPAthbooks}>
															<br/>
															<div className="card">
																<div className="card-body">
																	<div className="row">
																		<div className="col-md-4">
																			<img src={task.pathbook.cover["es"]} alt="feature" width="100"/>
																		</div>
																		<div className="col-md-8">
																			<br/>
																			<h6 className="card-title">{task.pathbook.title["es"]}</h6>
																			<p className="card-text">{task.pathbook.title["en"]}</p>
																			<p className="card-text">Calsificacion: {task.pathbook.classification}</p>
																			<button className="full-width botonCompraAhora" type="button" onClick={() => this.aletaPathbook(task.pathbook)}>Ver mas</button>
																		</div>
																	</div>
																</div>
															</div>
														</div>,
													)}
											</div>
										</div>
									)
									:
									null
							}
						</div>
					</div>
				</section>
				<div className="col-full">
					<br/>
					<a href="https://pathbooks.app" title="">
						<p className="display-2--light text-center">pathbooks.app</p>
					</a>
					<br/>
				</div>
				<Preloader/>
			</div>
		);
	}
}
