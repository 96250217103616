import React from "react";
import "../App.css";
import "../assets/cssPathbooks/stylePathbooks.css";
import "../assets/cssPathbooks/bootstrap.min.css";
import IconoIlimitado from "../assets/images/iconos/IconoIlimitado.png";
import IconoOffline from "../assets/images/iconos/IconoOffline.png";
import IconoSincronizacion from "../assets/images/iconos/IconoSincronizacion.png";
import IconoContendo from "../assets/images/iconos/IconoContendo.png";
import portadasImg from "../assets/images/banners/portadas.png";
import portadasImgKids from "../assets/images/banners/portadas-kids.png";
import banderas from "../assets/images/banners/banderas.png";
import banerMamaHija from "../assets/images/banners/vanerManaHija.jpg";
import telefonoIni from "../assets/images/celular.png";
import en from "../language/en";
import es from "../language/es";

export default class miniLnading extends React.Component {
	constructor(props) {
		super(props);
		console.log(this.props.divisa)
		this.state = {
			divisaCommission: this.props.divisa,
			txtPage: ""
		};
	}

	async selectLanguage() {
		if (this.state.divisaCommission === "USD") {
			await this.setState({txtPage: en.miniLnading});
		} else {
			await this.setState({txtPage: es.miniLnading});
		}
	};

	componentDidMount() {
		this.selectLanguage()
	}

	render() {
		return (
			<div>
				<div>
					<div className="areaTransparente">
						<div className="areaAzul">
							<div className="col-xl-10 col-md-10 offset-lg-1">
								<h1 className="tituloBlanco texCenter"><b className="tituloAmarillo">{this.state.txtPage.text00}</b>{this.state.txtPage.text01}</h1>
							</div>
						</div>
						<div className="col-xl-10 col-md-10 offset-lg-1">
							<div className="areaTransparente ">
								<h4>{this.state.txtPage.text02}</h4>
							</div>
						</div>
						<div className="areaAzul">
							<div className="col-xl-10 col-md-10 offset-lg-1">
								<h1 className="tituloBlanco texCenter">{this.state.txtPage.text03}</h1>
							</div>
						</div>

						<div className="portadasImg">
							<br/>
							<img width="90%" src={portadasImg} alt=""/>
						</div>

						<div>
							<br/>
							<h1 className="tituloNegro texCenter">{this.state.txtPage.text04}<b className="tituloAzul">{this.state.txtPage.text05}</b></h1>
						</div>

						<div className="portadasImg">
							<img width="90%" src={portadasImgKids} alt=""/>
						</div>
					</div>
					<div className="areaAzul">
						<div className="col-xl-10 col-md-10 offset-lg-1">
							<h1 className="tituloBlanco texCenter">{this.state.txtPage.text06}</h1>
							<h1 className="tituloBlanco texCenter">{this.state.txtPage.text07}<b className="tituloAmarillo">{this.state.txtPage.text08}</b></h1>
						</div>
					</div>
					<br/>
					<div className="features_area" id="pago">
						<div className="container">
							<div className="features_main_wrap">
								<div className="row  align-items-center">
									<div className="col-xl-2 col-lg-2 col-md-3 offset-lg-2">
										<div>
											<img className="center iconoLanding" width="90%" src={IconoIlimitado} alt=""/>
										</div>
									</div>
									<div className="col-xl-6 col-lg-6 col-md-6">
										<div className="col-xl-10 col-lg-10 col-md-10">
											<ul>
												<li className="wow fadeInUp" data-wow-duration=".7s" data-wow-delay=".5s">
													<h4 className="tituloNegro">{this.state.txtPage.text09}</h4>
													<h6 className="subTituloNegro">{this.state.txtPage.text10}</h6>
												</li>
											</ul>
										</div>
									</div>
								</div>
							</div>
							<br/>
							<div className="features_main_wrap">
								<div className="row  align-items-center">
									<div className="col-xl-2 col-lg-2 col-md-3 offset-lg-2">
										<div>
											<img className="center iconoLanding" width="90%" src={IconoOffline} alt=""/>
										</div>
									</div>
									<div className="col-xl-6 col-lg-6 col-md-6">
										<div className="col-xl-10 col-lg-10 col-md-10">
											<ul>
												<li className="wow fadeInUp" data-wow-duration=".7s" data-wow-delay=".5s">
													<h4 className="tituloNegro">{this.state.txtPage.text11}</h4>
													<h6 className="subTituloNegro">{this.state.txtPage.text12}</h6>
												</li>
											</ul>
										</div>
									</div>
								</div>
							</div>
							<br/>
							<div className="features_main_wrap">
								<div className="row  align-items-center">
									<div className="col-xl-2 col-lg-2 col-md-3 offset-lg-2">
										<div>
											<img className="center iconoLanding" width="90%" src={IconoSincronizacion} alt=""/>
										</div>
									</div>
									<div className="col-xl-6 col-lg-6 col-md-6">
										<div className="col-xl-10 col-lg-10 col-md-10">
											<ul>
												<li className="wow fadeInUp" data-wow-duration=".7s" data-wow-delay=".5s">
													<h4 className="tituloNegro">{this.state.txtPage.text13}</h4>
													<h6 className="subTituloNegro">{this.state.txtPage.text14}</h6>
												</li>
											</ul>
										</div>
									</div>
								</div>
							</div>
							<br/>
							<div className="features_main_wrap">
								<div className="row  align-items-center">
									<div className="col-xl-2 col-lg-2 col-md-3 offset-lg-2">
										<div>
											<img className="center iconoLanding" width="90%" src={IconoContendo} alt=""/>
										</div>
									</div>
									<div className="col-xl-6 col-lg-6 col-md-6">
										<div className="col-xl-10 col-lg-10 col-md-10">
											<ul>
												<li className="wow fadeInUp" data-wow-duration=".7s" data-wow-delay=".5s">
													<h4 className="tituloNegro">{this.state.txtPage.text15}</h4>
													<h6 className="subTituloNegro">{this.state.txtPage.text16}</h6>
												</li>
											</ul>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<br/>
				<div className="areaAzul">
					<div className="container">
						<div className="row align-items-center">
							<div className="col-xl-7 col-md-6">
								<h2 className="tituloBlanco texCenter">{this.state.txtPage.text17}</h2>
								<h1 className="tituloAmarillo texCenter">{this.state.txtPage.text18}</h1>
							</div>
							<div className="col-xl-5 col-md-6">
								<div className="phone_thumb wow fadeInDown">
									<img width="90%" src={telefonoIni} alt=""/>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="col-xl-10 col-md-10 offset-lg-1">
					<br/>
					<div className="areaTransparente ">
						<h3 className="tituloNegro texCenter">{this.state.txtPage.text19}<b className="tituloAzul">{this.state.txtPage.text20}</b>{this.state.txtPage.text21}</h3>
						<br/>
						<h3 className="tituloNegro texCenter">{this.state.txtPage.text22}<b className="tituloAzul">{this.state.txtPage.text23}</b>{this.state.txtPage.text24}</h3>
						<br/>
						<div className="portadasImg">
							<img width="90%" src={banderas} alt=""/>
							<br/>
							<br/>
							<br/>
							<img width="90%" src={banerMamaHija} alt=""/>
						</div>
					</div>
				</div>

				<footer className="footer">
					<div className="copy-right_text">
						<div className="container">
							<div className="row">
								<div className="col-xl-12">
									<br/>
									<p className="copy_right text-center">
										Copyright &copy;
										<script>document.write(new Date().getFullYear());</script>
										All rights reserved | Pathbooks
										<br/>
										<a href="https://pathbooks.app/terms">Terms and Conditions</a>
									</p>
								</div>
							</div>
						</div>
					</div>
				</footer>
			</div>
		);
	}
}
