import React from "react";
import "../App.css";
import "../assets/cssPathbooks/stylePathbooks.css";
import "../assets/cssPathbooks/bootstrap.min.css";

import logo from "../assets/images/logo.png";
import logoAndrois from "../assets/images/androidStore.png";
import logoIos from "../assets/images/appStore.png";
import querystring from "query-string";
import Preloader from "../preloader";
import swal from "sweetalert2";

import {firestore, auth} from "../firebase/firebase";
///import "../App.css";
import en from "../language/en";
import es from "../language/es";

export default class Coupons extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			language: "es",
			txtPage: "",
			txtSaludo: "",
			mode: "signup",
			name: null,
			uid: null,
			nombreReferente: "",
			idReferente: "",
			password1: "",
			password2: "",
			estatusForm: 1,
			fotoReferente: null,
		};
	};

	async selectMessages() {
		if (this.state.language === "en") {
			await this.setState({txtPage: en.referrals});
		} else {
			await this.setState({txtPage: es.referrals});
		}
	};

	async componentDidMount() {
		this.selectMessages();
		/////obtenemos codigo del usuario
		const values = await querystring.parse(this.props.location.search);
		this.setState({
			idReferente: values.rid,
		});
		await firestore.doc("users/" + values.rid).get().then((documentSnapshot) => {
			let dataRefenrente = documentSnapshot.data();
			if (documentSnapshot.data()) {
				if (dataRefenrente.name) {
					this.setState({
						nombreReferente: dataRefenrente.name,
						txtSaludo: this.state.txtPage.title01a + this.state.txtPage.title01,
					});
				} else if (dataRefenrente.username) {
					this.setState({
						nombreReferente: dataRefenrente.username,
						txtSaludo: this.state.txtPage.title01a + this.state.txtPage.title01,
					});
				} else if (dataRefenrente.email) {
					this.setState({
						nombreReferente: dataRefenrente.email,
						txtSaludo: this.state.txtPage.title01a + this.state.txtPage.title01,
					});
				}
				if (dataRefenrente.photoURL) {
					this.setState({
						fotoReferente: dataRefenrente.photoURL,
					});
				} else {
					this.setState({
						txtSaludo: this.state.txtPage.title01b + this.state.txtPage.title01,
					});
				}
			}
		});

		let parent = this;
		auth.onAuthStateChanged(function (user) {
			if (user) {
				parent.setState({
					name: user.displayName,
					uid: user.uid,
					mode: "code",
				});
			} else {
				parent.setState({
					name: null,
					uid: null,
					mode: "signup",
				});
			}
		});
	};

	textChange = (e) => {
		this.setState({
			[e.target.name]: e.target.value,
		});
	};
	facebook = (e) => {
		var provider = new auth.FacebookAuthProvider();
		provider.addScope("public_profile");
		provider.addScope("email");
		auth.languageCode = "en-US";
		//		console.log(provider);
		// provider.setCustomParameters({
		// 	"display": "popup",
		// });
		auth.signInWithPopup(provider);
		// this.registrausuario(res.user.uid, res.user.email);
		e.preventDefault();
	};

	createuser = (e) => {
		if (this.state.password1 !== this.state.password2) {
			swal.fire(this.state.txtPage.alertA01, this.state.txtPage.alertB01, this.state.txtPage.alert);
		} else if (!this.state.email) {
			swal.fire(this.state.txtPage.alertA02, this.state.txtPage.alertB02, this.state.txtPage.alert);
		} else if (!this.state.email) {
			swal.fire(this.state.txtPage.alertA02, this.state.txtPage.alertB02, this.state.txtPage.alert);
		} else {
			auth.createUserWithEmailAndPassword(this.state.email, this.state.password1)
				.then(res => {
					auth.currentUser.updateProfile({
						displayName: this.state.name,
					});
					this.registrausuario(res.user.uid, res.user.email);
				}).catch(function (error) {
				swal.fire("Error", error.code, "error");
			});
		}
		e.preventDefault();
	};

	registrausuario(uid, email) {
		firestore.collection("users").doc(uid).set({
			email: email,
			username: this.state.name,
			referrer: firestore.doc("users/" + this.state.idReferente),
		});
		this.setState({
			estatusForm: 2,
		});
	};

	async changeLanguage() {
		if (this.state.language === "en") {
			await this.setState({language: "es"});
			await this.setState({txtPage: es.referrals});
		} else {
			await this.setState({language: "en"});
			await this.setState({txtPage: en.referrals});
		}
	};

	render() {
		return (
			<div className="body">
				<div className="barraChica_area imagenBarraChica">
					<div className="container">
						<button className="boton_Languge" style={{float: "right"}} onClick={() => this.changeLanguage()}><u>{this.state.txtPage.btnText03}</u></button>
						<div className="row ">
							<img className="logoBarraChica" src={logo} alt=""/>
						</div>
						<br/>
						<h3 className="tituloBlanco texCenter">Stories for everyone, have a look and live a new experience</h3>
					</div>
				</div>
				<div className="areaPrecios">
					{
						this.state.fotoReferente
							? (
								<div className="container">
									<div className="row ">
										<img className="avatar center" src={this.state.fotoReferente} alt={"userfoto"}/>
									</div>
								</div>
							)
							: null
					}
					{
						this.state.nombreReferente
							? (
								<div className="container">
										<h4 className="tituloAzul texCenter">{this.state.nombreReferente}</h4>
										<h4 className="subTituloGris texCenter">{this.state.txtPage.title01a + " " + this.state.txtPage.title01}</h4>
								</div>
							)
							:
							(
								<div className="container">
									<h4 className="tituloAzul texCenter">{this.state.nombreReferente}</h4>
									<h4 className="subTituloGris texCenter">{this.state.txtPage.title01b + " " + this.state.txtPage.title01}</h4>
								</div>
							)
					}
					<div className="container">
						<div className="">
							<div className=" " data-wow-duration=".5s" data-wow-delay=".5s">
								<br/>
								<br/>
							</div>
						</div>
						<div className="single_prising  " data-wow-duration=".7s" data-wow-delay=".8s">
							<div className="prising_header"  style={{background: "#144469"}}>
								<h3 className="tituloBlanco texCenter">{this.state.txtPage.title02}</h3>
							</div>
							<div className="col-xl-8 col-md-8 centrarColumna">
							{
								this.state.estatusForm === 1
									? (
										<form name="contactForm" id="contactForm" onSubmit={this.createuser}>
											<br/>
											<fieldset>
												<h4 className="display-2--light text-center">{this.state.txtPage.title03}</h4>
												<div className="form-field">
													<input name="name" type="text" placeholder={this.state.txtPage.impText01} onChange={this.textChange} className="form-control"/>
												</div>
												<br/>
												<div className="form-field">
													<input name="email" type="email" placeholder={this.state.txtPage.impText02} onChange={this.textChange} className="form-control"/>
												</div>
												<br/>
												<div className="form-field">
													<input name="password1" type="password" placeholder={this.state.txtPage.impText03} onChange={this.textChange} className="form-control"/>
												</div>
												<br/>
												<div className="form-field">
													<input name="password2" type="password" placeholder={this.state.txtPage.impText04} onChange={this.textChange} className="form-control"/>
												</div>
												<br/>
												<div className="form-field">
													<button className="botonCompraAhora">{this.state.txtPage.btnText01}</button>
													{/*<button className="full-width btn--primary" type="button" onClick={this.facebook}><i className="fab fa-facebook-square"/> {this.state.txtPage.btnText02}</button>*/}
												</div>
											</fieldset>
										</form>
									)
									: null
							}
							{
								this.state.estatusForm === 2
									? (
										<div>
											<fieldset>
												<h4 className="display-2--light text-center">{this.state.txtPage.title04}</h4>
												<br/>

												<div className="form-field">
													<div className="container">

														<div className="row row-inputs">
															<div className="col-sm-6">
																<a href="https://itunes.apple.com/us/app/living-a-book-pathbooks-libros-interactivos/id1233961624" title="">
																	<img src={logoIos} style={{width: "28%", minWidth: 200}} alt=""/>
																	<h4 className="tituloAzul texCenter">{this.state.txtPage.text01}</h4>
																</a>
															</div>
															<br/>
															<h4 className="display-2--light text-center">o</h4>

															<div className="col-sm-6">
																<a href="https://play.google.com/store/apps/details?id=com.livingabook.app" title="">
																	<img src={logoAndrois} style={{width: "28%", minWidth: 200}} alt=""/>
																	<h4 className="tituloAzul texCenter">{this.state.txtPage.text02}</h4>
																</a>
															</div>
														</div>
														<h4 className="txtNegro texCenter">{this.state.txtPage.title05}</h4>
														<p className="txtNegro texCenter">{this.state.txtPage.text03}</p>

													</div>
												</div>
											</fieldset>
										</div>
									)
									: null
							}
							</div>
						</div>
					</div>
				</div>

				<Preloader/>
			</div>
		);
	}
}
