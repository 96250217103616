import React from "react";
import "../App.css";
import "../assets/cssPathbooks/stylePathbooks.css";
import "../assets/cssPathbooks/bootstrap.min.css";
import querystring from "query-string";
import moment from "moment";
import swal from "sweetalert2";
import {firestore, auth} from "../firebase/firebase";

import ManualDescarga from "../download/downloadManual";

import logo from "../assets/images/logo.png";
import offertImg from "../assets/images/offers/ofertaEspecial.png";
import Helmet from "react-helmet";

import {PayPalButton} from "react-paypal-button-v2";

import en from "../language/en";
import es from "../language/es";

export default class payment extends React.Component {
	constructor(props) {
		super(props);
		const values = querystring.parse(this.props.location.search);
		this.state = {
			typeBoton: [],
			estatusForm: 1,
			userData: "",

			userDataDos: "",

			userName: "",
			email: "",
			password: "",

			userNameDos: "",
			emailDos: "",
			passwordDos: "",

			paymentsPaypal: null,
			paymentsSubOfferPaypal: false,
			paypalAmount: 0,
			paypalDays: 0,

			paymentAmount: 0,
			paymentDays: 0,
			paymentColor: "#fff",
			idBoton: values.rid,
			paymentTxtTitleSubOffer: "",
			paymentAmountSubOffer: "",
			paymentDaysSubOffer: "",
			divisaCommission: values.divi,

			contIniciados: 0,
			contPagados: 0,
			contRegUsuarios: 0,
			contSubOffert: 0,

			quantityUser: 0,

			txtPage: "",
		};
		this.changeManagement = this.changeManagement.bind(this);
	};

	async selectLanguage() {
		if (this.state.divisaCommission === "USD") {
			await this.setState({txtPage: en.payment});
		} else {
			await this.setState({txtPage: es.payment});
		}
	};

	changeManagement(event) {
		const name = event.target.name;
		this.setState({
			[name]: event.target.value,
		});
	}

	createuser = () => {
		if (!this.state.userName) {
			swal.fire("Ingresa un nombre de usuario", "Gracias", "error");
		} else if (!this.state.email) {
			swal.fire("Ingresa un Email", "Gracias", "error");
		} else if (!this.state.password) {
			swal.fire("El password debe tener al menos 6 caracteres", "Gracias", "error");
		} else if (!this.state.email) {
			swal.fire("Ingresa un Email", "Gracias", "error");
		} else {
			auth.createUserWithEmailAndPassword(this.state.email, this.state.password)
				.then(user => {
					this.setState({
						userData: user,
					});
					this.contadorRegistroUsuarios();
					this.guardaPagoPayPal();

				})
				.catch(error => {
					if (error.code === "auth/invalid-email") {
						swal.fire("Email no valido", "Ingresa un email correcto", "error");
					}
					if (error.code === "auth/email-already-in-use") {
						swal.fire("La dirección de correo electrónico ya está en uso", "Ingresa un email diferente", "error");
					}
					if (error.code === "auth/weak-password") {
						swal.fire("El password debe tener al menos 6 caracteres", "Gracias", "error");
					}
				});
			this.setState({
				estatusForm: 3,
			});

		}
	};
	createuseDos = () => {
		if (!this.state.userName) {
			swal.fire("Ingresa un nombre de usuario", "Gracias", "error");
		} else if (!this.state.email) {
			swal.fire("Ingresa un Email", "Gracias", "error");
		} else if (!this.state.password) {
			swal.fire("El password debe tener al menos 6 caracteres", "Gracias", "error");
		} else if (!this.state.email) {
			swal.fire("Ingresa un Email", "Gracias", "error");
		} else if (!this.state.userNameDos) {
			swal.fire("Ingresa un nombre del segundo usuario", "Gracias", "error");
		} else if (!this.state.emailDos) {
			swal.fire("Ingresa un Email del segundo  usuario", "Gracias", "error");
		} else if (!this.state.passwordDos) {
			swal.fire("El password del segundo  usuario debe tener al menos 6 caracteres", "Gracias", "error");
		} else if (!this.state.emailDos) {
			swal.fire("Ingresa un Email del segundo  usuario", "Gracias", "error");
		} else {
			auth.createUserWithEmailAndPassword(this.state.email, this.state.password)
				.then(user => {
					this.setState({
						userData: user,
					});
					this.contadorRegistroUsuarios();
					this.guardaPagoPayPal();

				})
				.catch(error => {
					if (error.code === "auth/invalid-email") {
						swal.fire("Email no valido", "Ingresa un email correcto", "error");
					}
					if (error.code === "auth/email-already-in-use") {
						swal.fire("La dirección de correo electrónico ya está en uso", "Ingresa un email diferente", "error");
					}
					if (error.code === "auth/weak-password") {
						swal.fire("El password debe tener al menos 6 caracteres", "Gracias", "error");
					}
				});

			auth.createUserWithEmailAndPassword(this.state.emailDos, this.state.passwordDos)
				.then(userDos => {
					this.setState({
						userDataDos: userDos,
					});
					this.contadorRegistroUsuarios();
					this.guardaPagoPayPalDos();

				})
				.catch(error => {
					if (error.code === "auth/invalid-email") {
						swal.fire("Email no valido", "Ingresa un email correcto", "error");
					}
					if (error.code === "auth/email-already-in-use") {
						swal.fire("La dirección de correo electrónico ya está en uso", "Ingresa un email diferente", "error");
					}
					if (error.code === "auth/weak-password") {
						swal.fire("El password debe tener al menos 6 caracteres", "Gracias", "error");
					}
				});

			this.setState({
				estatusForm: 4,
			});
		}
	};

	async guardaPagoPayPal() {
		///// jalar los datos
		var idUser = this.state.userData.user.uid;
		//// cadena a guardar
		await firestore.collection("users").doc(idUser).set({
			id: idUser,
			email: this.state.email,
			timestamp: moment().toDate(),
			username: this.state.userName,
			payments: null,
			paymentMethod: "PayPal",
		});
		const messageRef = await firestore.collection("users/" + idUser + "/payments").doc();
		await messageRef.set({payments: this.state.paymentsPaypal});
		this.guardaRecibo(messageRef.id);
	}

	async guardaPagoPayPalDos() {
		///// jalar los datos
		var idUser = this.state.userData.user.uid;
		//// cadena a guardar
		await firestore.collection("users").doc(idUser).set({
			id: idUser,
			email: this.state.email,
			timestamp: moment().toDate(),
			username: this.state.userName,
			payments: null,
			paymentMethod: "PayPal",
		});
		const messageRef = await firestore.collection("users/" + idUser + "/payments").doc();

		///// jalar los datos
		var idUserDos = this.state.userDataDos.user.uid;
		//// cadena a guardar
		await firestore.collection("users").doc(idUserDos).set({
			id: idUserDos,
			email: this.state.email,
			timestamp: moment().toDate(),
			username: this.state.userName,
			payments: null,
			paymentMethod: "PayPal",
		});
		await messageRef.set({payments: this.state.paymentsPaypal});
		this.guardaReciboDos(messageRef.id);
	}

	async guardaPagoPayPalSub() {
		///// jalar los datos
		var idUser = this.state.userData.user.uid;
		const messageRef = await firestore.collection("users/" + idUser + "/payments").doc();
		await messageRef.set({payments: this.state.paymentsPaypal});
		this.guardaRecibo(messageRef.id);
	}

	async guardaRecibo(recibo) {
		var idUser = this.state.userData.user.uid;
		await firestore.collection("receipts").doc().set({
			expires: moment().add(this.state.paypalDays, "days").toDate(),
			id: "web_offer",
			platform: "web",
			promo: false,
			receipt: firestore.doc("users/" + idUser + "/payments/" + recibo),
			sandbox: false,
			timestamp: moment().toDate(),
			token: null,
			user: firestore.doc("users/" + idUser),
			valid: true,
			paymentMethod: "PayPal",
			paypalAmount: this.state.paypalAmount,
			offert: this.state.paymentsSubOfferPaypal,
		});
	}

	async guardaReciboDos(recibo) {
		var idUser = this.state.userData.user.uid;
		await firestore.collection("receipts").doc().set({
			expires: moment().add(this.state.paypalDays, "days").toDate(),
			id: "web_offer_parejas",
			platform: "web",
			promo: false,
			receipt: firestore.doc("users/" + idUser + "/payments/" + recibo),
			sandbox: false,
			timestamp: moment().toDate(),
			token: null,
			user: firestore.doc("users/" + idUser),
			valid: true,
			paymentMethod: "PayPal",
			paypalAmount: this.state.paypalAmount,
			offert: this.state.paymentsSubOfferPaypal,
		});

		var idUserDos = this.state.userDataDos.user.uid;
		await firestore.collection("receipts").doc().set({
			expires: moment().add(this.state.paypalDays, "days").toDate(),
			id: "web_offer_parejas",
			platform: "web",
			promo: false,
			receipt: firestore.doc("users/" + idUserDos + "/payments/" + recibo),
			sandbox: false,
			timestamp: moment().toDate(),
			token: null,
			user: firestore.doc("users/" + idUserDos),
			valid: true,
			paymentMethod: "PayPal",
			paypalAmount: this.state.paypalAmount,
			offert: this.state.paymentsSubOfferPaypal,
		});
	}


	miniOferta = async () => {
		await this.setState({
			paymentTxtTitleSubOffer: this.state.paymentTxtTitleSubOffer,
			paypalAmount: this.state.paymentAmountSubOffer,
			paypalDays: this.state.paymentDays + this.state.paymentDaysSubOffer,
			paymentsSubOfferPaypal: true,
		});
		console.log(this.state.paymentDays);
		await this.setState({estatusForm: 5});
	};

	miniRegresar = () => {
		this.setState({estatusForm: 3});
	};
	irPasoTres = () => {
		this.setState({estatusForm: 4});
	};

	headerOffer() {
		return (
			<div className="prising_header" style={{background: this.state.paymentColor}}>
				<div>
					<h2 className="tituloBlanco texCenter">{this.state.paymentTxtTitle}</h2>
					<h1 className="tituloBlanco texCenter">{"$ " + this.state.paymentAmount + " " + this.state.divisaCommission}</h1>
					<h3 className="subTituloBlanco texCenter">{this.state.paymentTxtSubTitle}</h3>
				</div>
			</div>
		);
	}

	footerOffert() {
		return (
			<div className="col-xl-10 col-md-10 centrarColumna">
				<br/>
				<br/>
				<h3 className="subTituloAzul texCenter">{this.state.paymentTxtBeneficios}</h3>
				<div className="col-xl-6 col-md-6 centrarColumna">
				<ul>
					<h5 className="txtNegro"><i className="fas fa-check"/> {this.state.paymentTxt1}</h5>
					<h5 className="txtNegro"><i className="fas fa-check"/> {this.state.paymentTxt2}</h5>
					<h5 className="txtNegro"><i className="fas fa-check"/> {this.state.paymentTxt3}</h5>
					<h5 className="txtNegro"><i className="fas fa-check"/> {this.state.paymentTxt4}</h5>
				</ul>
				</div>
			</div>
		);
	}

	formOffert() {
		return (
			<div className="col-xl-10 col-md-10 centrarColumna">
				<br/>
				<h2 className="subTituloAzul texCenter">{this.state.txtPage.text06}</h2>
				{
					this.state.quantityUser === 1
						?
						<div className="col-xl-8 col-md-6 centrarColumna">
							<p className="texCenter txtNegro">{this.state.txtPage.text07}</p>
							<br/>
							{/*<small id="emailHelp" className="form-text text-muted">Importante para poder darte una cuen.</small>*/}
							<div className="form-group">
								<label>{this.state.txtPage.text08}</label>
								<input value={this.state.userName} name="userName" placeholder={this.state.txtPage.text08} type="text" className="form-control" onChange={this.changeManagement}/>
							</div>
							<div className="form-group">
								<label htmlFor="exampleInputEmail1">{this.state.txtPage.text09}</label>
								<input value={this.state.email} name="email" placeholder="Email" type="email" className="form-control" onChange={this.changeManagement}/>
							</div>
							<div className="form-group">
								<label htmlFor="exampleInputEmail1">{this.state.txtPage.text10}</label>
								<input value={this.state.password} name="password" placeholder="Password" type="password" className="form-control" onChange={this.changeManagement}/>
							</div>

							<div className="col col-md-10 centrarColumna">
								<br/>
								<button className="botonCompraAhora" onClick={this.createuser}>{this.state.txtPage.text11}</button>
							</div>
						</div>
						: null
				}
				{
					this.state.quantityUser === 2
						?
						<div className="centrarColumna">
							<p className="texCenter txtNegro">{this.state.txtPage.text06}</p>
							<br/>
							<div className="container">
								<div className="row">
									<div className="col-sm">
										<h2 className="subTituloAzul texCenter">{this.state.txtPage.text12}</h2>
										<p className="texCenter txtNegro">{this.state.txtPage.text13}</p>
										<div className="form-group">
											<label>{this.state.txtPage.text08}</label>
											<input value={this.state.userName} name="userName" placeholder="{this.state.txtPage.text08}" type="text" className="form-control" onChange={this.changeManagement}/>
										</div>
										<div className="form-group">
											<label htmlFor="exampleInputEmail1">{this.state.txtPage.text09}</label>
											<input value={this.state.email} name="email" placeholder="Email" type="email" className="form-control" onChange={this.changeManagement}/>
										</div>
										<div className="form-group">
											<label htmlFor="exampleInputEmail1">{this.state.txtPage.text10}</label>
											<input value={this.state.password} name="password" placeholder="Password" type="password" className="form-control" onChange={this.changeManagement}/>
										</div>
									</div>
									<div className="col-sm">
										<h2 className="subTituloAzul texCenter">{this.state.txtPage.text14}</h2>
										<p className="texCenter txtNegro">{this.state.txtPage.text15}</p>
										<div className="form-group">
											<label>{this.state.txtPage.text08}</label>
											<input value={this.state.userNameDos} name="userNameDos" placeholder="{this.state.txtPage.text08}" type="text" className="form-control" onChange={this.changeManagement}/>
										</div>
										<div className="form-group">
											<label htmlFor="exampleInputEmail1">{this.state.txtPage.text09}</label>
											<input value={this.state.emailDos} name="emailDos" placeholder="Email" type="email" className="form-control" onChange={this.changeManagement}/>
										</div>
										<div className="form-group">
											<label htmlFor="exampleInputEmail1">{this.state.txtPage.text10}</label>
											<input value={this.state.passwordDos} name="passwordDos" placeholder="Password" type="password" className="form-control" onChange={this.changeManagement}/>
										</div>
									</div>
									<div className="col col-md-10 centrarColumna">
										<br/>
										<button className="botonCompraAhora" onClick={this.createuseDos}>{this.state.txtPage.text16}</button>
									</div>

								</div>
							</div>

						</div>
						: null
				}
			</div>
		);
	}
	/////contadores de estadisticas
	async contadorPagados() {
		this.leerContadoresOffer();
		const idOffer = this.state.idBoton
		firestore.collection("offers").doc(idOffer).update({
			contPagados: parseInt(this.state.contPagados + 1),
		});
	}
	async contadorPagadosSubOffer() {
		this.leerContadoresOffer();
		const idOffer = this.state.idBoton
		firestore.collection("offers").doc(idOffer).update({
			contSubOffert: parseInt(this.state.contSubOffert + 1),
		});
	}
	async contadorRegistroUsuarios() {
		this.leerContadoresOffer();
		const idOffer = this.state.idBoton
		firestore.collection("offers").doc(idOffer).update({
			contRegUsuarios: parseInt(this.state.contRegUsuarios + 1),
		});
	}

	leerContadoresOffer(){
		firestore.doc("offers/" + this.state.idBoton).get().then((documentSnapshot) => {
			let data = documentSnapshot.data();
			this.setState({
				contPagados: data.contPagados,
				contRegUsuarios: data.contRegUsuarios,
				contSubOffert: data.contSubOffert,
			});
		});
	}

	leerDatosOffer(){
		firestore.doc("offers/" + this.state.idBoton).get().then((documentSnapshot) => {
			let data = documentSnapshot.data();
			this.setState({
				paymentAmount: data.amount,
				paymenttxtHeader: data.txtHeader,
				paymentColor: data.color,
				paymentDays: data.days,
				paymentTxtSubTitle: data.subTitle,
				paymentTxtTitle: data.title,
				paymentTxt1: data.txt01,
				paymentTxt2: data.txt02,
				paymentTxt3: data.txt03,
				paymentTxt4: data.txt04,
				paymentTxtBeneficios: data.txtBeneficios,
				paymentTxtTitleSubOffer: data.titleSubOffer,
				paymentAmountSubOffer: data.amountSubOffer,
				paymentDaysSubOffer: data.daysSubOffer,
				paypalAmount: data.amount,
				paypalDays: data.days,
				quantityUser: data.quantityUser,
				paymentDivisa: data.divisa,
			});
		});
	}


	createCurencyPaypal = () => {
		const parent = this;
		let valuePay = 0
		if(this.state.estatusForm === 1){
			valuePay = this.state.paymentAmount
		} else{
			valuePay = this.state.paymentAmountSubOffer
		}
		return (
			<div>
				<PayPalButton
					createOrder={(data, actions) => {
						return actions.order.create({
							purchase_units: [{
								amount: {
									value: valuePay
								}
							}],
						});
					}}
					onApprove={(data, actions) => {
						// Capture the funds from the transaction
						return actions.order.capture().then(function(details) {
							// Show a success message to your buyer
							swal.fire({
								title: "Pago realizado con exito " + details.payer.name.given_name + "!",
								text: "Continuar",
								icon: "success",
								confirmButtonText: "Cool",
							});
							console.log(details)
							if (parent.state.paymentsSubOfferPaypal === false) {
								parent.setState({
									estatusForm: 2,
									paymentsPaypal: details,
								});
								parent.contadorPagados();
							} else {
								parent.setState({
									estatusForm: 4,
									paymentsPaypal: details,
								});
								parent.contadorPagadosSubOffer();
								parent.guardaPagoPayPalSub();
							}
						});
					}}
				/>

			</div>
		)
	};

	async componentDidMount() {
		/////valor de link para empresas
		this.selectLanguage()
		await this.leerDatosOffer();

	}

	render() {
		const variableDivisa = "https://www.paypal.com/sdk/js?client-id=AWgykr3hnzF0u2Xy1zwdtTt7SoJbqMN3eMMDGXzUg6ZW6xi6zzfAZslXpyaBbqsfYaqM7aB-3bBTRzb8&currency=" + this.state.divisaCommission;
		return (
			<div className="body">
				<Helmet>
					<script src={variableDivisa} data-sdk-integration-source="button-factory"/>
				</Helmet>
				<div className="barraChica_area imagenBarraChica">
					<div className="">
						<div className="col-xl-12 col-md-12 centrarColumna">
							<img className="logoBarraChica" src={logo} alt=""/>
							<h2 className="tituloAzulClaro texCenter">{this.state.paymenttxtHeader}</h2>
						</div>
					</div>
				</div>
				<div className="areaPrecios">
					<div className="col-xl-12 col-md-12 centrarColumna">
						<h3 className="subTituloGris texCenter ">{this.state.txtPage.text01}</h3>
						<h2 className="tituloAzul texCenter ">{this.state.txtPage.text02}</h2>
					</div>
					<div className="container">
						<div className="">
							<div className=" " data-wow-duration=".5s" data-wow-delay=".5s">
								<br/>
								<br/>
							</div>
						</div>
						<div className="single_prising  " data-wow-duration=".7s" data-wow-delay=".8s">
							{
								this.state.estatusForm === 1
									? (
										<div>
											{this.headerOffer()}
											<div className="col-xl-8 col-md-8 centrarColumna">
												<br/>
												<h2 className="tituloAzul texCenter">{this.state.txtPage.text03}</h2>
												<h3 className="subTituloNegro texCenter">{this.state.txtPage.text04}</h3>
												<br/>
												{this.createCurencyPaypal()}
												{this.footerOffert()}
											</div>
										</div>
									)
									: null
							}
							{
								this.state.estatusForm === 2
									? (
										<div>
											{this.headerOffer()}
											{this.formOffert()}
											{this.footerOffert()}
										</div>
									)
									: null
							}
							{
								this.state.estatusForm === 3
									? (
										<div>
											{this.headerOffer()}
											<div className="col-xl-10 col-md-10 centrarColumna">
												<br/>
												<h2 className="tituloAzul texCenter">{this.state.userName + " gracias por tu compra"}</h2>
												<h4 className=" subTituloNegro texCenter">{this.state.txtPage.text17}</h4>
												<div className="row">
													<div className="col col-md-5">
														<br/>
														<img className="imgOffert " src={offertImg} alt=""/>
														<br/>
														<h4 className=" subTituloNegro texCenter">{this.state.txtPage.text18}</h4>
													</div>
													<div className="col col-md-7">
														<br/>
														<br/>
														<h3 className="tituloAzul texCenter">{this.state.paymentTxtTitleSubOffer}</h3>
														<h1 className="tituloAzul texCenter">{"por solo $" + this.state.paymentAmountSubOffer + " " + this.state.divisaCommission} </h1>
														<br/>
														<h4 className=" subTituloNegro texCenter">{this.state.txtPage.text19}</h4>
														<br/>
														<button className="aprobechaAhora " onClick={this.miniOferta}>{this.state.txtPage.text20}</button>
														<br/>
														<br/>
														<h6 className=" subTituloNegro texCenter">{this.state.txtPage.text21}</h6>

														<button className="botonCompraAhora" onClick={this.irPasoTres}>{this.state.txtPage.text22}</button>
													</div>
												</div>
											</div>
										</div>
									)
									: null
							}
							{
								this.state.estatusForm === 4
									? (
										<div>

											<div className="col-xl-10 col-md-10 centrarColumna">
												<br/>
												<br/>
												<h1 className="subTituloAzul texCenter">{this.state.txtPage.text23}</h1>
												{
													this.state.paymentsSubOfferPaypal === false
														?(
															<div className="col-xl-6 col-md-6 centrarColumna">
																<br/>
																<button className="bttnOferta " onClick={this.miniOferta}>{this.state.txtPage.text24}</button>
															</div>
														)
														:null
												}
												<ManualDescarga divisa={this.state.divisaCommission}/>
											</div>
										</div>
									)
									: null
							}
							{
								this.state.estatusForm === 5
									? (
										<div className="container col-xl-10 col-md-10 centrarColumna">
											<div className="row">
												<div className="col-xl-8 col-md-8 centrarColumna">
													<br/>
													<h2 className="tituloAzul texCenter">{this.state.paymentTxtTitleSubOffer}</h2>
													<h1 className="tituloAzul texCenter">{this.state.txtPage.text27 + " $" + this.state.paymentAmountSubOffer + " " + this.state.divisaCommission}</h1>
													<br/>
													<h5 className="subTituloNegro texCenter">{this.state.txtPage.text25}</h5>
													<br/>
													{this.createCurencyPaypal()}
												</div>
												<br/>
												<br/>
												<div className="col-xl-5 col-md-5 centrarColumna">
													<br/>
													<button className="botonCompraAhora" onClick={this.miniRegresar}>{this.state.txtPage.text26}</button>
												</div>
											</div>
										</div>
									)
									: null
							}

						</div>
					</div>
				</div>
			</div>
		);
	}
}
