import React from "react";
export default class Preloader extends React.Component {
	render() {
		return (
			<div id="preloader">
				<div id="loader">
					<div className="line-scale-pulse-out">
						<div></div>
						<div></div>
						<div></div>
						<div></div>
						<div></div>
					</div>
				</div>
			</div>
		)
	}
}