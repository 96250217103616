import React, {Component} from "react";
import logoAndrois from "../assets/images/androidStore.png";

class playstoreDownload extends Component {
	render() {
		return (
			<div className="botonDescarga">
				<a href="https://play.google.com/store/apps/details?id=com.livingabook.app" target="_blank" rel="noopener noreferrer" title="">
					<img className=" botonDescargaImg" src={logoAndrois} alt=""/>
				</a>
			</div>
		);
	}
}

export default playstoreDownload;
