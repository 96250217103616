import React, {Component} from "react";
import AppstoreDownload from "./appstoreDownload";
import PlaystoreDownload from "./playstoreDownload";
import tutorial1 from "../assets/images/tutorialUser/imagen1.png";
import tutorial2 from "../assets/images/tutorialUser/imagen2.png";
import tutorial3 from "../assets/images/tutorialUser/imagen3.png";
import en from "../language/en";
import es from "../language/es";

class downloadManual extends Component {
	constructor(props) {
		super(props);

		this.state = {
			divisaCommission: this.props.divisa,
			txtPage: "USD"
		};
	}

	async selectLanguage() {
		console.log(this.props.divisa)
		if (this.props.divisa === "USD") {
			await this.setState({txtPage: en.download});
		} else {
			await this.setState({txtPage: es.download});
		}
	};

	componentDidMount() {
		this.selectLanguage()
	}

	render() {
		return (
			<div>
				<br/>
				<h2 className="tituloAzul texCenter">{this.state.txtPage.text01}</h2>
				<div className="row">
					<div className="col-md-6">
						<br/>
						<div className="row">
							<div className="card-body">
								<h5 className="card-title texCenter">{this.state.txtPage.text02}</h5>
								<p className="card-text texCenter">{this.state.txtPage.text03}</p>
								<AppstoreDownload/>
							</div>
							<div className="card-body">
								<h5 className="card-title texCenter">{this.state.txtPage.text04}</h5>
								<p className="card-text texCenter">{this.state.txtPage.text05}</p>
								<PlaystoreDownload/>
							</div>
						</div>
					</div>
					<div className="col-md-6">
						<br/>
						<p className="card-text texCenter">{this.state.txtPage.text06}</p>
						<h6 className="subTituloNegro texCenter">{this.state.txtPage.text07}<b className="subTituloAzul">{this.state.txtPage.text08}</b>{this.state.txtPage.text09}</h6>
						<img className="imgTutorial" src={tutorial1} alt=""/>
						<h6 className="subTituloNegro texCenter">{this.state.txtPage.text10}<p className="subTituloAzul ">{this.state.txtPage.text11}</p></h6>
						<img className="imgTutorial" src={tutorial2} alt=""/>
						<h6 className="subTituloNegro texCenter">{this.state.txtPage.text12}<b className="subTituloAzul ">{this.state.txtPage.text13}</b>{this.state.txtPage.text14}</h6>
						<img className="imgTutorial" src={tutorial3} alt=""/>
						<h5 className="subTituloAzul texCenter">{this.state.txtPage.text15}</h5>
					</div>
				</div>
			</div>

		);
	}
}

export default downloadManual;
