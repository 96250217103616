import React from "react";
import logo from "../assets/images/logo.png";
import Preloader from "../preloader";
import querystring from "query-string";
import swal from "sweetalert2";
import axios from "axios";
import {firestore, auth} from "../firebase/firebase";
import "../App.css";
import en from "../language/en";
import es from "../language/es";
import ManualDescarga from "../download/downloadManual";

export default class Coupons extends React.Component {
	constructor(props) {
		super(props);
		const values = querystring.parse(this.props.location.search);
		this.state = {
			mode: "signup",
			name: null,
			uid: null,
			lang: values.lang,
			txtPage: "",
			divisaCommission: "USD"
		};
	}
	async selectLanguage() {
		if (this.state.lang === "es") {
			await this.setState({
				txtPage: es.coupons,
				divisaCommission: "MXN"
			});
		} else {
			await this.setState({
				txtPage: en.coupons
			});
		}
	};

	async componentDidMount() {

		await this.selectLanguage()
		var parent = this;
		auth.onAuthStateChanged(async function (user) {
			console.log(user)
			if (user) {
				parent.setState({
					name: user.email,
					uid: user.uid,
					mode: "code",
				});
				await firestore.doc("users/" + user.uid).set({
					notes: "Create by coupons",
					type: "USER",
					email: user.email,
					name: user.email
				});
			} else {
				parent.setState({
					name: null,
					uid: null,
					mode: "signup",
				});
			}
		});
	}

	textChange = (e) => {
		this.setState({
			[e.target.name]: e.target.value,
		});
	};
	facebook = (e) => {
		var provider = new auth.FacebookAuthProvider();
		provider.addScope("public_profile");
		provider.addScope("email");
		auth.languageCode = "en-US";

		provider.setCustomParameters({
			"display": "popup",
		});

		auth.signInWithPopup(provider);
		e.preventDefault();
	};
	signout = (e) => {
		auth.signOut();
		e.preventDefault();
	};
	createuser = (e) => {
		if (this.state.password1 !== this.state.password2) {
			swal.fire(this.state.txtPage.alertTxt02, this.state.txtPage.alertTxt03, "warning");
		} else {
			auth.createUserWithEmailAndPassword(this.state.email, this.state.password1)
				.then(res => {
					auth.currentUser.updateProfile({
						displayName: this.state.name,
					});
				}).catch(function (error) {
				swal.fire(this.state.txtPage.alertTxt02, error.message, "warning");
			});
		}
		e.preventDefault();
	};
	signin = (e) => {
		auth.signInWithEmailAndPassword(this.state.email, this.state.password).catch(function (error) {
			swal.fire(this.state.txtPage.alertTxt02, error.message, "warning");
		});
		e.preventDefault();
	};
	submitCode = (e) => {
		e.preventDefault();
		return this.sendCode();
	};
	sendCode = async () => {
		const userRef = await firestore.doc("users/" + this.state.uid).get();
		if (!userRef.exists) {
			await firestore.doc("users/" + this.state.uid).set({
				notes: this.state.txtPage.alertTxt04,
			});
		}
		if (this.state.code) {
			axios.post("https://us-central1-pathbook.cloudfunctions.net/api/code?uid=" + this.state.uid + "&code=" + this.state.code)
				.then(res => {
					this.setState({mode: "charged"})
					swal.fire(this.state.txtPage.alertTxt01, this.state.txtPage.alertTxt05, "success");
				}).catch(err => {
				swal.fire(this.state.txtPage.alertTxt02, err.response.data, "error");
			});
		}
	};

	render() {
		return (
			<div className="body">
				<div className="barraChica_area imagenBarraChica">
					<div className="container">
						<div className="row ">
							<img className="logoBarraChica" src={logo} alt=""/>
						</div>
						<br/>
						<h3 className="tituloBlanco texCenter">{this.state.txtPage.text01}</h3>
					</div>
				</div>
				<section id="contact">
					<div className="areaPrecios">
						<div className="container">
							<div className="single_prising text-center wow " data-wow-duration=".7s" data-wow-delay=".8s">
								<div className="prising_header" style={{background: "#144469"}}>
									<h1 className="tituloBlanco texCenter">{this.state.txtPage.text02}</h1>
								</div>
								<div className="col-xl-8 col-md-8 centrarColumna">
									{
										this.state.mode === "signup" ?
											<form name="contactForm" id="contactForm" onSubmit={this.createuser}>
												<fieldset>
													<br/>
													<h1 className="tituloNegro text-center">{this.state.txtPage.text03}</h1>

													<div className="form-field">
														<input name="name" type="text" required placeholder={this.state.txtPage.impText01} onChange={this.textChange} className="full-width form-control"/>
														<br/>
													</div>
													<div className="form-field">
														<input name="email" type="email" required placeholder={this.state.txtPage.impText02} onChange={this.textChange} className="full-width form-control"/>
														<br/>
													</div>
													<div className="form-field">
														<input name="password1" type="password" required placeholder={this.state.txtPage.impText03} onChange={this.textChange} className="full-width form-control"/>
														<br/>
													</div>
													<div className="form-field">
														<input name="password2" type="password" required placeholder={this.state.txtPage.impText04} onChange={this.textChange} className="full-width form-control"/>
														<br/>
													</div>
													<div className="form-field">
														<button className="full-width botonCompraAhora">{this.state.txtPage.textBtn01}</button>
													</div>
													<div className="form-field">
														<br/>
														<button className="full-width botonCompraAhora" type="button" onClick={this.facebook}><i className="fab fa-facebook-square"/> {this.state.txtPage.textBtn02}</button>
													</div>
												</fieldset>
												<br/>
												<p>{this.state.txtPage.text06}</p>
												<h6 className="tituloNegro text-center">{this.state.txtPage.text04}
													<a href="#1" onClick={() => this.setState({mode: "signin"})}>{this.state.txtPage.text05}</a> <br/>
												</h6>

											</form>
											: null
									}

									{
										this.state.mode === "signin" ?
											<form name="contactForm" id="contactForm" onSubmit={this.signin}>
												<fieldset>
													<br/>
													<h1 className="tituloNegro text-center">{this.state.txtPage.text07}</h1>
													<br/>
													<div className="form-field">
														<input name="email" type="email" required placeholder={this.state.txtPage.impText02} onChange={this.textChange} className="full-width form-control"/>
													</div>
													<div className="form-field">
														<br/>
														<input name="password" type="password" required placeholder={this.state.txtPage.impText06} onChange={this.textChange} className="full-width form-control"/>
													</div>
													<div className="form-field">
														<br/>
														<button className="full-width btn--primary botonCompraAhora">{this.state.txtPage.impText05}</button>
													</div>
													<div className="form-field">
														<br/>
														<button className="full-width btn--primary botonCompraAhora" type="button" onClick={this.facebook}><i className="fab fa-facebook-square"/> {this.state.txtPage.textBtn02}</button>
													</div>
												</fieldset>

												<br/>
												<p>{this.state.txtPage.text08}</p>

												<h6 className="tituloNegro text-center">{this.state.txtPage.text09}
													<a href="#1" onClick={() => this.setState({mode: "signup"})}> {this.state.txtPage.text10}</a> <br/>
												</h6>

											</form>
											: null
									}

									{
										this.state.mode === "code" ?
											<form name="contactForm" id="contactForm" onSubmit={this.submitCode}>
												<fieldset>
													<br/>
													<h3 className="text-center">{this.state.txtPage.text11}</h3>
													<p>{this.state.txtPage.text12} {this.state.name} <br/><a href="#1" onClick={this.signout}>{this.state.txtPage.text13}</a></p>
													<div className="form-field">
														<input name="code" type="text" placeholder={this.state.txtPage.impText07} onChange={this.textChange} className="full-width form-control"/>
													</div>
													<div className="form-field">
														<br/>
														<button className="full-width btn--primary botonCompraAhora">{this.state.txtPage.textBtn03}</button>
													</div>
												</fieldset>
											</form>
											: null
									}
									{
										this.state.mode === "charged" ?
											<form name="contactForm" id="contactForm" onSubmit={this.submitCode}>
												<fieldset>
													<br/>
													<h3 className="tituloNegro text-center">{this.state.txtPage.text14}</h3>
													<h5 className="text-center">{this.state.txtPage.text15}</h5>
													<h5 className="text-center">{this.state.txtPage.text16}</h5>
													<h5 className="text-center">{this.state.txtPage.text17}</h5>

												</fieldset>
												<ManualDescarga divisa={this.state.divisaCommission}/>
												<p><a href="#1" onClick={this.signout}>{this.state.txtPage.text18}</a></p>
											</form>
											: null
									}
								</div>
							</div>
						</div>
					</div>
				</section>
				<Preloader/>
			</div>
		);
	}
}
