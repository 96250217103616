import React from "react";
import logo from "../assets/images/logo.png";

import AppstoreDownload from "./appstoreDownload";
import PlaystoreDownload from "./playstoreDownload";
import en from "../language/en";
import es from "../language/es";

export default class Download extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			language: "en",
			txtPage: "",
			txtSaludo: "",
		};
	};

	async selectMessages() {
		if (this.state.language === "en") {
			await this.setState({txtPage: en.referrals});
		} else {
			await this.setState({txtPage: es.referrals});
		}
	};

	async componentDidMount() {
		this.selectMessages();
	};

	async changeLanguage() {
		if (this.state.language === "en") {
			await this.setState({language: "es"});
			await this.setState({txtPage: es.referrals});
		} else {
			await this.setState({language: "en"});
			await this.setState({txtPage: en.referrals});
		}
	};

	render() {
		return (

			<div className="body">
				<div className="barraChica_area imagenBarraChica">
					<div className="">
						<div className="col-xl-12 col-md-12 centrarColumna">
							<img  className="logoBarraChica" src={logo} alt=""/>
							<h2 className="tituloAzulClaro texCenter">{this.state.paymenttxtHeader}</h2>
						</div>
					</div>
				</div>
				<div className="areaPrecios">
					<div className="container">

						<div className="">
							<div className=" " data-wow-duration=".5s" data-wow-delay=".5s">
								<br/>
								<br/>
							</div>
						</div>

						<div className="single_prising text-center wow " data-wow-duration=".7s" data-wow-delay=".8s">
							<div className="prising_header" style={{background: "#144469"}}>
								<h4 className="subTituloBlanco texCenter">{this.state.txtPage.text05}</h4>
							</div>
							<div className="col-xl-8 col-md-8 centrarColumna">
								<br/>
							</div>
							<br/>
							<div className="col-xl-6 col-md-6 centrarColumna">
								<h5 className="card-title texCenter">{this.state.txtPage.text01}</h5>
								<AppstoreDownload/>
								<br/>
							</div>
							<div className="col-xl-6 col-md-6 centrarColumna">
								<h5 className="card-title texCenter">{this.state.txtPage.text02}</h5>
								<PlaystoreDownload/>
							</div>


							<div className="col-full">
								<br/>
								<a href="https://pathbooks.app" title="">
									<p className="display-2--light text-center">{this.state.txtPage.text04}</p>
								</a>
								<br/>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}
