import React, {Component} from "react";
import logoIos from "../assets/images/appStore.png";

class appstoreDownload extends Component {
	render() {
		return (
			<div className="botonDescarga">
				<a href="https://itunes.apple.com/us/app/living-a-book-pathbooks-libros-interactivos/id1233961624" target="_blank" rel="noopener noreferrer" title="">
					<img className=" botonDescargaImg" src={logoIos} alt=""/>
				</a>
			</div>
		);
	}
}
export default appstoreDownload;
