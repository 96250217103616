const es = {
	affiliates: {
		text01: "Gracias por registrarte en nuestro sistema de afiliados",
		text01a: "Modulo de afiliados",
		text02: "Este es el momento de generar tu link de afiliado por lo que necesitas llenar los siguientes datos",
		text03: "Te recomendamos tener a la mano los siguientes datos además de una fotografía para tu perfil",
		text04: "¿Ya tienes una cuenta? ",
		text05: "Inicia sesión dando clic aquí",
		text06: "Este apartado es para usuarios nuevos.",
		text07: "Si eres un afiliado registrado inicia sesión",
		text08: "¿No tienes una cuenta?",
		text09: "¿Quieres registrarte?",
		text10: "haz clic aquí",
		text11: "Agrega una fotografía tuya ",
		text12: "Hola !",
		text13: "Cerrar sesión",
		text14: "Cobrado correctamente",
		text15: "Tu cupón fue agregado a tu cuenta de manera exitosa",
		text16: "Ahora solo tienes que descargar e iniciar sesión en Pathbooks",
		text17: "Sigue esta guía para continuar",
		text18: "Salir clic aquí",
		text19: "El id tiene que ser minimo de 6 caracteres maximo de 10 sin espacios o caracteres extraños",
		text20: "Recupera tu contraseña",
		text21: "Ingresa tu coreo electrónico, y presiona (recuperar tu contraseña), te mandaremos un email de recuperación",
		text22: "Olvide mi contraseña",
		text23: "¿Quieres recuperar tu contraseña?",
		impText01: "Ingresa tu nombre",
		impText02: "Correo electrónico",
		impText03: "Crear contraseña",
		impText04: "Confirmar contraseña",
		impText05: "Iniciar sesión",
		impText06: "Contraseña",
		impText07: "Ingrese tu código",
		impText08: "Ingresa tu id de usuario",
		impText09: "Ingresa tu numero telefónico",
		textBtn01: "Crear cuenta",
		textBtn02: "Continuar con Facebook",
		textBtn03: "Aplicar código",
		textBtn04: "Recupera ahora",
		alertTxt01: "¡Éxito!",
		alertTxt02: "¡Ups!",
		alertTxt03: "Las contraseñas no coinciden",
		alertTxt04: "Esta cuenta fue creada por un código",
		alertTxt05: "El código se aplicó correctamente",
		txtFooter01: "Recuerda al llenar estos datos:",
		txtFooter02: "* Este registro es totalmente gratis",
		txtFooter03: "* Generaras un link por una oferta de $299.00 pesos mexicanos para tus clientes",
		txtFooter04: "* La comisión de cada venta es de 25%",
		txtFooter05: "* Solo podrás retirar el dinero por medio PayPal",
		txtFooter06: "* Necesitas ser mayor de edad para poder ser candidato afiliado",
		txtFooter07: "* Si los requisitos no se cumplen no podrás retirar el dinero generado",
		txtFooter08: "* Si tienes dudas contacta a soporte:",
	},
	coupons: {
		text01: "Historias para todos, echa un vistazo y vive una nueva experiencia",
		text02: "Pantalla de activación de código",
		text03: "Crea una cuenta",
		text04: "¿Ya tienes una cuenta? ",
		text05: "haz clic aquí",
		text06: "Este apartado es para usuarios nuevos.",
		text07: "Iniciar sesión",
		text08: "¿No tienes una cuenta?",
		text09: "¿Quieres registrarte?",
		text10: "haz clic aquí",
		text11: "Ingresa tu código",
		text12: "Hola !",
		text13: "Cerrar sesión",
		text14: "Cobrado correctamente",
		text15: "Tu cupón fue agregado a tu cuenta de manera exitosa",
		text16: "Ahora solo tienes que descargar e iniciar sesión en Pathbooks",
		text17: "Sigue esta guía para continuar",
		text18: "Salir",
		impText01: "Nombre",
		impText02: "Correo electrónico",
		impText03: "Crear contraseña",
		impText04: "Confirmar contraseña",
		impText05: "Iniciar sesión",
		impText06: "Contraseña",
		impText07: "Ingrese su código",
		textBtn01: "Crear cuenta",
		textBtn02: "Continuar con Facebook",
		textBtn03: "Aplicar código",
		alertTxt01: "¡Éxito!",
		alertTxt02: "¡Ups!",
		alertTxt03: "Las contraseñas no coinciden",
		alertTxt04: "Esta cuenta fue creada por un código",
		alertTxt05: "El código se aplicó correctamente",
	},
	referrals: {
		title01a: "te regala",
		title01b: "Te regalamos",
		title01: "un cupón para que disfrutes de PATHBOOKS por 7 días gratis.",
		title02: "Solo sigue los siguientes pasos para poder canjear tu premio.",
		title03: "Paso 1 - Registra tu usuario.",
		title04: "Paso 2 - Descargar PATHBOOKS",
		title05: "Paso 3 - Dentro del app",
		impText01: "Tu nombre",
		impText02: "Tu email",
		impText03: "Crea tu password",
		impText04: "Confirnma tu password",
		btnText01: "Crear cuenta",
		btnText02: "Continuar con Facebook",
		btnText03: "English",
		text01: "Descargar para iOS",
		text02: "Descargar para Android",
		text03: "Una ves dentro del app busca el botón de configuración, se encuentra en la parte de abajo del lado derecho, inicia sesión con los mismos datos que registraste.",
		text04: "© Copyright Pathbooks",
		text05: "Descarga ahora",
		alert: "Entendido",
		alertA01: "Oops",
		alertB01: "Los passwords no coinciden",
		alertA02: "Agrega un email",
		alertB02: "Vació agrega un email para continuar",
	},
	payment: {
		text01: "Miles de personas en todo el mundo ya disfrutan Pathbooks",
		text02: "En 3 simples pasos podrás tener grandes beneficios",
		text03: "Utiliza el método de pago de tu preferencia",
		text04: "Paso 1",
		text05: "Utiliza el método de pago de tu preferencia",
		text06: "Paso 2",
		text07: "Llena los datos del usuario",
		text08: "Nombre de usuario",
		text09: "Ingresa un correo",
		text10: "Ingresa un password",
		text11: "Continuar",
		text12: "Primer usuario",
		text13: "Usuario principal",
		text14: "Segundo usuario",
		text15: "Usuario secundario",
		text16: "Registrar y continuar",
		text17: "En agradecimiento tenemos para ti una super oferta",
		text18: "Disponible solo en este momento",
		text19: "Disfruta mucho más tiempo",
		text20: "Aprovechar ahora",
		text21: "Ahora no gracias",
		text22: "Ir a paso 3",
		text23: "Paso 3",
		text24: "Ver oferta",
		text25: "Utiliza el método de pago de tu preferencia",
		text26: "Regresar",
		text27: "Por solo"
	},
	download: {
		text01: "Descarga el app e inicia sesión",
		text02: "Usuarios iOS",
		text03: "Si utilizas un dispositivo iOS descarga en:",
		text04: "Usuarios Android",
		text05: "Si utilizas un dispositivo android descarga en:",
		text06: "Con el usuario y la contraseña que registramos en el paso dos.",
		text07: "Busca el botón de ",
		text08: "configuración",
		text09: " en la parte de abajo como se muestra en la imagen.",
		text10: "Presiona el botón que dice ",
		text11: "Iniciar Sesión / Crear Cuenta",
		text12: "Presiona el botón de ",
		text13: "iniciar sesión",
		text14: " e ingresa los datos de usuario del paso 2",
		text15: "Comienza a disfrutar una increíble experiencia de lectura",
	},
	commission: {
		text00: "En 3 simples pasos podrás tener grandes beneficios",
		text01: "Paso 2",
		text02: "Utiliza el método de pago de tu preferencia",
		text03: "Paso 1",
		text04: "Llena los datos de usuario",
		text05: "Nombre de usuario",
		text06: "Ingresa un correo",
		text07: "Ingresa un password",
		text08: "Continuar",
		text09: "En agradecimiento tenemos para ti una super oferta.",
		text10: "Disponible solo en este momento",
		text11: "Por solo $ ",
		text12: "Disfruta mucho más tiempo.",
		text13: "Aprovechar ahora",
		text14: "Ahora no gracias",
		text15: "Ir a paso 3",
		text16: "En agradecimiento tenemos para ti una super oferta",
		text17: "Ver oferta",
		text18: "Paso 3",
		text19: "En agradecimiento tenemos para ti una super oferta.",
		text20: "Por solo $ ",
		text21: "Utiliza el método de pago de tu preferencia.",
		text22: "Pago realizado con exito",
		text23: "Continuar",
		text28: "Regresar",
		text29: "Ingresa un nombre de usuario",
		text30: "Gracias",
		text31: "Ingresa un Email",
		text32: "El password debe tener al menos 6 caracteres",
		text33: "Email no valido",
		text34: "Ingresa un email correcto",
		text35: "La dirección de correo electrónico ya está en uso",
		text36: "Ingresa un email diferente",
		text37: "El password debe tener al menos 6 caracteres",
	},
	miniLnading: {
		text00: "Pathbooks",
		text01: " es la primera plataforma de lectura interactiva",
		text02: "Pathbooks está reinventando el concepto de lectura. Si te gustó el final del último libro que leíste, ahora tienes el poder de cambiar el curso de la historia y crear tramas únicas basadas en sus decisiones. Historias inmersivas e intensas ¿Quién dijo que leer era aburrido? ",
		text03: "Tenemos una gran colección",
		text04: "Además del modo ",
		text05: "infantil ",
		text06: "Esta oferta le brinda los grandes beneficios de",
		text07: "Pathbooks",
		text08: "Pro",
		text09: "Pathbooks ilimitados",
		text10: "Tendrás todo el contenido disponible para leer, además de audiolibros, series y mejoras en nuestra aplicación",
		text11: "Modo offline",
		text12: "Puedes descargar tu contenido favorito para que nunca dejes de leer, tú decides qué libros descargar y así tu lectura favorita te acompañará",
		text13: "Sincronización entre dispositivos",
		text14: "Todos tus dispositivos se sincronizarán para que puedas leer donde quieras y cambiar cuando quieras",
		text15: "Nuevo contenido semanal",
		text16: "Una gran comunidad de escritores súper talentosos está preparando nuevo contenido para que siempre tenga algo nuevo para leer",
		text17: "Adquiere ahora acceso completo a la biblioteca más grande de ",
		text18: "contenido interactivo",
		text19: "Hacemos que la lectura sea ",
		text20: "accesible, interactiva y divertida ",
		text21: "actualmente ofrecemos opciones en inglés y español",
		text22: "Puedes encontrar",
		text23: " muchas obras ",
		text28: " creado por personas de todo el mundo",
	},
};

export default es;
