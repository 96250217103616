import React from "react";
import logo from "../assets/images/logo.png";
import Preloader from "../preloader";
import querystring from "query-string";
import swal from "sweetalert2";
import {firestore, auth, storage} from "../firebase/firebase";
import Moment from "moment";
import {CopyToClipboard} from "react-copy-to-clipboard";
import "../App.css";
import en from "../language/en";
import es from "../language/es";

export default class affiliates extends React.Component {

	constructor(props) {
		super(props);
		const values = querystring.parse(this.props.location.search);
		this.state = {
			mode: "signup",
			userID: "",
			name: null,
			uid: null,
			lang: values.lang,
			txtPage: "",
			divisaCommission: "USD",
			phone: "",
			email: null,
			password: null,
			userPic: null,
			afiliadoDate: null,
			fotoAutor: null,
			barraLoadEnd1: 100,
			barraLoad1: 0,
			message1: "Seleciona un archivo .JPG para cargar",
			urlIni: "https://firebasestorage.googleapis.com/v0/b/pathbook.appspot.com/o/affiliate%2F",
			urlmid: "%2Fimages%2F",
			urlEnd: "?alt=media",

		};
	}

	textChange = (e) => {
		this.setState({
			[e.target.name]: e.target.value,
		});
	};

	async selectLanguage() {
		if (this.state.lang === "es") {
			await this.setState({
				txtPage: es.affiliates,
				divisaCommission: "MXN",
			});
		} else {
			await this.setState({
				txtPage: en.affiliates,
				divisaCommission: "USD",
			});
		}
	};

	async componentDidMount() {
		await this.selectLanguage();
		let parent = this;
		await auth.onAuthStateChanged(function (user) {
			if (user) {
				parent.verificaAfiliadoOAdmin(user.uid);
			} else {
				parent.setState({
					name: null,
					uid: null,
					mode: "signup",
				});
			}
		});
	}

	verificaAfiliadoOAdmin(user) {
		firestore.doc("users/" + user).get().then((documentSnapshot) => {
			let dataUser = documentSnapshot.data();
			if (!dataUser) {
				auth.signOut();
			} else if (dataUser.affiliate) {
				this.setState({
					email: dataUser.email,
					uid: user,
					name: dataUser.name,
					mode: "code",
					userPic: dataUser.userPic,
				});
				this.cargaDatosDeAfiliado(dataUser.email);
			} else {
				this.setState({mode: "signinReg", email: dataUser.email, uid: user})
				// this.usuarioNoregistrado(user.uid)
//				auth.signOut();
			}
		});
	}

	validaConvertirUser = () => {
		let patt = new RegExp(/^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/im);
		if (patt.test(this.state.phone) === false) {
			swal.fire({title: "Numero de telefono no valido", text: "Ingresa un numero de 10 digitos sin simbolos o espacios", type: "error", confirmButtonText: "Entendido"});
		} else if (this.state.name === null) {
			swal.fire({title: "Ingresa un nombre", text: "Gracias", type: "error", confirmButtonText: "Entendido"});
		} else if (this.state.userID.length < 5) {
			swal.fire({title: "Ingresa un ID minimo de 6 caracteres", text: "Gracias", type: "error", confirmButtonText: "Entendido"});
		} else if (!this.state.userID) {
			swal.fire({title: "Ingresa un ID valido para el usuario", text: "Gracias", type: "error", confirmButtonText: "Entendido"});
		} else {
			firestore.collection("affiliate").where("txtLink", "==", this.state.userID).get().then((documentSnapshot) => {
				if (documentSnapshot.size === 0) {
					this.usuarioNoregistrado();
				} else {
					swal.fire({title: "El id de afiliado ya esta ocupado", text: "Intenta con otro", type: "error", confirmButtonText: "Entendido"});
				}
			});
		}
	};

	async usuarioNoregistrado(){

		const idUser = this.state.uid
		await firestore.collection("users").doc(idUser).update({
			affiliate: true,
		});
		await firestore.collection("affiliate").doc(idUser).set({
			user: firestore.doc("users/" + idUser),
			name: this.state.name,
			txtLink: this.state.userID,
			phone: this.state.phone,
			incorporator: "webRegistration",
			picAfiliado: null,
			divisa: "MXN",
			amount: 299,
			percentage: 25,
			color: "#08345e",
			days: 365,
			subTitle: "Un regalo por tiempo limitado",
			title: "Esta promoción te dará acceso a PathbooksPro por un año a un increíble precio",
			txtHeader: "Aprovecha esta gran oferta ahora",
			email: this.state.email,
			modified: Moment().toDate(),
			contIniciados: 0,
			contPagados: 0,
			contRegUsuarios: 0,
			contSubOffert: 0,
			titleSubOffer: "En pathbooks te premiamos por tu preferencia",
			amountSubOffer: 99,
			daysSubOffer: 365,
		});
		this.verificaAfiliadoOAdmin(idUser)
	}

	cargaDatosDeAfiliado(email) {
		firestore.collection("affiliate").where("email", "==", email).onSnapshot(async querySnapshot => {
			let dataUser = querySnapshot.docs;
			await dataUser.forEach((dataConsulta) => {
				if (dataConsulta.data().picAfiliado) {
					this.setState({
						mode: "perfil",
						afiliadoDate: dataConsulta.data()
					});
				} else {
					this.setState({
						mode: "picture",
						afiliadoDate: dataConsulta.data()
					});
				}
			});

		});
	}


	signout = (e) => {
		auth.signOut();
		e.preventDefault();
	};

	validaCreateuser = () => {
		let patt = new RegExp(/^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/im);
		if (patt.test(this.state.phone) === false) {
			swal.fire({title: "Numero de telefono no valido", text: "Ingresa un numero de 10 digitos sin simbolos o espacios", type: "error", confirmButtonText: "Entendido"});
		} else if (this.state.name === null) {
			swal.fire({title: "Ingresa un nombre", text: "Gracias", type: "error", confirmButtonText: "Entendido"});
		} else if (this.state.email === null) {
			swal.fire({title: "Ingresa un ID minimo de 6 caracteres", text: "Gracias", type: "error", confirmButtonText: "Entendido"});
		} else if (this.state.password1 !== this.state.password2) {
			swal.fire(this.state.txtPage.alertTxt02, this.state.txtPage.alertTxt03, "warning");
		} else if (this.state.userID.length < 5) {
			swal.fire({title: "Ingresa un ID minimo de 6 caracteres", text: "Gracias", type: "error", confirmButtonText: "Entendido"});
		} else if (!this.state.userID) {
			swal.fire({title: "Ingresa un ID valido para el usuario", text: "Gracias", type: "error", confirmButtonText: "Entendido"});
		} else {
			firestore.collection("affiliate").where("txtLink", "==", this.state.userID).get().then((documentSnapshot) => {
				if (documentSnapshot.size === 0) {
					this.createuser();
				} else {
					swal.fire({title: "El id de afiliado ya esta ocupado", text: "Intenta con otro", type: "error", confirmButtonText: "Entendido"});
				}
			});
		}
	};

	createuser() {
		if (this.state.password1 !== this.state.password2) {
			swal.fire(this.state.txtPage.alertTxt02, this.state.txtPage.alertTxt03, "warning");
		} else {
			auth.createUserWithEmailAndPassword(this.state.email, this.state.password1)
				.then(res => {
					auth.currentUser.updateProfile({
						displayName: this.state.name,
					});
					this.registeruseraffiliate(res);
				}).catch(function (error) {
				if (error.code === "auth/invalid-email") {
					swal.fire({title: "Email no valido", text: "Verifica tu email", type: "error", confirmButtonText: "Entendido"});
				}
				if (error.code === "auth/email-already-in-use") {
					swal.fire({title: "La dirección de correo electrónico ya está en uso", text: "si ya tienes una cuenta inicia sesion", type: "error", confirmButtonText: "Entendido"});
				}
				if (error.code === "auth/weak-password") {
					swal.fire({title: "La contraseña debe tener al menos 6 caracteres", text: "Verifica tu email", type: "error", confirmButtonText: "Entendido"});
				}
			});
		}
	};

	registeruseraffiliate(res) {
		var idUser = res.user.uid;
		firestore.collection("users").doc(idUser).set({
			email: this.state.email,
			timestamp: Moment().toDate(),
			name: this.state.name,
			userPic: null,
			authorData: null,
			type: "user",
			affiliate: true,
			phone: this.state.phone,
		});
		firestore.collection("affiliate").doc(idUser).set({
			user: firestore.doc("users/" + idUser),
			name: this.state.name,
			txtLink: this.state.userID,
			phone: this.state.phone,
			incorporator: "webRegistration",
			picAfiliado: null,
			divisa: "MXN",
			amount: 299,
			percentage: 25,
			color: "#08345e",
			days: 365,
			subTitle: "Un regalo por tiempo limitado",
			title: "Esta promoción te dará acceso a PathbooksPro por un año a un increíble precio",
			txtHeader: "Aprovecha esta gran oferta ahora",
			email: this.state.email,
			modified: Moment().toDate(),
			contIniciados: 0,
			contPagados: 0,
			contRegUsuarios: 0,
			contSubOffert: 0,
			titleSubOffer: "En pathbooks te premiamos por tu preferencia",
			amountSubOffer: 99,
			daysSubOffer: 365,
		});
	}

	signin = () => {
		if (this.state.email === null) {
			swal.fire({title: "Ingresa tu email", text: "Verifica tu email", type: "error", confirmButtonText: "Entendido"});
		} else if (this.state.password === null) {
			swal.fire({title: "Ingresa tu password", text: "Verifica tu password", type: "error", confirmButtonText: "Entendido"});
		} else {
			auth.signInWithEmailAndPassword(this.state.email, this.state.password)
				.then((user) => {
					this.verificaAfiliadoOAdmin(user.user)
				})
				.catch(error => {
					if (error.code === "auth/user-not-found") {
						swal.fire({title: "Email no valido", text: "Verifica tu email", type: "error", confirmButtonText: "Entendido"});
					}
					if (error.code === "auth/invalid-email") {
						swal.fire({title: "Email no valido", text: "Verifica tu email", type: "error", confirmButtonText: "Entendido"});
					}
					if (error.code === "auth/wrong-password") {
						swal.fire({title: "Password no valido", text: "Verifica tu password", type: "error", confirmButtonText: "Entendido"});
					}
				});
		}
	};

	restaurarPass = () => {
		auth.sendPasswordResetEmail(this.state.email).then(async function () {
			await swal.fire({
				title: "Revisa tu correo",
				text: "para restablecer tu contraseña",
				type: "success",
				confirmButtonText: "Entendido",
			});
			this.setState({mode: "signup"})
		}).catch(function (error) {
			if (error.code === "auth/invalid-email") {
				swal.fire({
					title: "Email no valido",
					text: "Ingresa un email correcto",
					type: "error",
					confirmButtonText: "Entendido",
				});
			} else if (error.code === "auth/user-not-found") {
				swal.fire({
					title: "Email no registrado",
					text: "Ingresa un email registrado",
					type: "error",
					confirmButtonText: "Entendido",
				});

			} else {
				swal.fire({
					title: "Error",
					text: "error",
					type: "error",
					confirmButtonText: "Entendido",
				});
			}
		});
	}

	cargaFotoAfiliado(event) {
		const namePict = "foto.jpg";
		const file = event.target.files[0];
		const storageRef = storage.ref("affiliate/" + this.state.uid + "/images").child(namePict);
		const task = storageRef.put(file);
			task.on("state_changed", (snapshot) => {
				this.setState({
					barraLoadEnd1: snapshot.totalBytes,
					barraLoad1: snapshot.bytesTransferred,
				});

			}, (error) => {
				this.setState({
					message1: "Error: " + error,
				});
			}, () => {
				this.setState({
					message1: "Cargado correctamente",
					fotoAutor: this.state.urlIni + this.state.uid + this.state.urlmid + task.snapshot.metadata.name + this.state.urlEnd,
				});
				this.guardaFoto();
			});
	}

	async guardaFoto() {
		await firestore.collection("affiliate").doc(this.state.uid).update({
			picAfiliado: this.state.fotoAutor,
		});
		swal.fire({
			title: "Guardado con exito",
			text: "",
			type: "success",
			confirmButtonText: "Ok",
		});
	}

	linkcopiado (){
		swal.fire({
			title: "Link copiado con exito",
			text: "Pégalo en donde te sea mas útil",
			type: "success",
			confirmButtonText: "Ok",
		});
	}

	render() {
		return (
			<div className="body">
				<div className="barraChica_area imagenBarraChica">
					<div className="container">
						<div className="row ">
							<img className="logoBarraChica" src={logo} alt=""/>
						</div>
						<br/>
						<h3 className="tituloBlanco texCenter">{this.state.txtPage.text01}</h3>
					</div>
				</div>
				<section id="contact">
					<div className="areaPrecios">
						<div className="container">
							<div className="single_prising text-center wow " data-wow-duration=".7s" data-wow-delay=".8s">
								<div className="prising_header" style={{background: "#144469"}}>
									<h1 className="subTituloBlanco texCenter">{this.state.txtPage.text01a}</h1>
								</div>
								<div className="col-xl-8 col-md-8 centrarColumna">
									{
										this.state.mode === "signup" ?
											<div>
												<br/>
												<h3 className="tituloNegro texCenter">{this.state.txtPage.text02}</h3>
												<fieldset>
													<br/>
													<h6>{this.state.txtPage.text06}</h6>
													<h6 className="tituloNegro text-center">
														{this.state.txtPage.text04}
														<a href="#1" onClick={() => this.setState({mode: "signin"})}>{this.state.txtPage.text05}</a> <br/>
													</h6>
													<br/>
													{/*<h1 className="subTituloNegro text-center">{this.state.txtPage.text03}</h1>*/}
													<h4 className="subTituloNegro text-center">{this.state.txtPage.text03}</h4>
													<br/>
													<div className="form-field">
														<input name="userID" maxLength="14" type="text" placeholder={this.state.txtPage.impText08} onChange={this.textChange} className="full-width form-control"/>
														<label>{this.state.txtPage.text19}</label>
														<br/>
														<br/>
													</div>
													<div className="form-field">
														<input name="name" type="text" placeholder={this.state.txtPage.impText01} onChange={this.textChange} className="full-width form-control"/>
														<br/>
													</div>
													<div className="form-field">
														<input name="phone" type="tel" pattern="[0-9]" maxLength="10" placeholder={this.state.txtPage.impText09} onChange={this.textChange} className="full-width form-control"/>
														<br/>
													</div>
													<div className="form-field">
														<input name="email" type="email" placeholder={this.state.txtPage.impText02} onChange={this.textChange} className="full-width form-control"/>
														<br/>
													</div>
													<div className="form-field">
														<input name="password1" type="password" placeholder={this.state.txtPage.impText03} onChange={this.textChange} className="full-width form-control"/>
														<br/>
													</div>
													<div className="form-field">
														<input name="password2" type="password" placeholder={this.state.txtPage.impText04} onChange={this.textChange} className="full-width form-control"/>
														<br/>
													</div>
													<div className="form-field">
														<button className="botonCompraAhora" onClick={this.validaCreateuser}>{this.state.txtPage.textBtn01}</button>
													</div>
												</fieldset>
												<br/>

												<div className="">
													<h3 className="tituloNegro text-center">{this.state.txtPage.txtFooter01}</h3>
													<h6 className="text-center">{this.state.txtPage.txtFooter02}</h6>
													<h6 className="text-center">{this.state.txtPage.txtFooter03}</h6>
													<h6 className="text-center">{this.state.txtPage.txtFooter04}</h6>
													<h6 className="text-center">{this.state.txtPage.txtFooter05}</h6>
													<h6 className="text-center">{this.state.txtPage.txtFooter06}</h6>
													<h6 className="text-center">{this.state.txtPage.txtFooter07}</h6>
													<h6 className="text-center">{this.state.txtPage.txtFooter08} <a href="mailto:support@pathbooks.app">support@pathbooks.app</a></h6>
												</div>

											</div>
											: null
									}

									{
										this.state.mode === "signin" ?
											<div>
												<fieldset>
													<br/>
													<h3 className="tituloNegro text-center">{this.state.txtPage.text07}</h3>
													<br/>
													<div className="form-field">
														<input name="email" type="email" placeholder={this.state.txtPage.impText02} onChange={this.textChange} className="full-width form-control"/>
													</div>
													<div className="form-field">
														<br/>
														<input name="password" type="password" placeholder={this.state.txtPage.impText06} onChange={this.textChange} className="full-width form-control"/>
													</div>

													<div className="form-field">
														<br/>
														<button className="botonCompraAhora" onClick={this.signin}>{this.state.txtPage.impText05}</button>
													</div>
												</fieldset>
												<br/>
												<p>{this.state.txtPage.text08}</p>
												<h6 className="tituloNegro text-center">{this.state.txtPage.text09}
													<a href="#1" onClick={() => this.setState({mode: "signup"})}> {this.state.txtPage.text10}</a><br/>
												</h6>
												<br/>
												<h6 className="tituloNegro text-center">{this.state.txtPage.text23}
													<a href="#1" onClick={() => this.setState({mode: "recuperapass"})}> {this.state.txtPage.text10}</a><br/>
												</h6>
											</div>
											: null
									}
									{
										this.state.mode === "signinReg" ?
											<div>
												<br/>
												<h3 className="tituloNegro texCenter">{this.state.txtPage.text02}</h3>
												<fieldset>
													<br/>

													<br/>
													{/*<h1 className="subTituloNegro text-center">{this.state.txtPage.text03}</h1>*/}
													<h4 className="subTituloNegro text-center">{this.state.txtPage.text03}</h4>
													<br/>
													<div className="form-field">
														<input name="userID" maxLength="14" type="text" placeholder={this.state.txtPage.impText08} onChange={this.textChange} className="full-width form-control"/>
														<label>{this.state.txtPage.text19}</label>
														<br/>
														<br/>
													</div>
													<div className="form-field">
														<input name="name" type="text" placeholder={this.state.txtPage.impText01} onChange={this.textChange} className="full-width form-control"/>
														<br/>
													</div>
													<div className="form-field">
														<input name="phone" type="tel" pattern="[0-9]" maxLength="10" placeholder={this.state.txtPage.impText09} onChange={this.textChange} className="full-width form-control"/>
														<br/>
													</div>

													<div className="form-field">
														<button className="botonCompraAhora" onClick={this.validaConvertirUser}>{this.state.txtPage.textBtn01}</button>
													</div>
												</fieldset>
												<br/>

												<div className="">
													<h3 className="tituloNegro text-center">{this.state.txtPage.txtFooter01}</h3>
													<h6 className="text-center">{this.state.txtPage.txtFooter02}</h6>
													<h6 className="text-center">{this.state.txtPage.txtFooter03}</h6>
													<h6 className="text-center">{this.state.txtPage.txtFooter04}</h6>
													<h6 className="text-center">{this.state.txtPage.txtFooter05}</h6>
													<h6 className="text-center">{this.state.txtPage.txtFooter06}</h6>
													<h6 className="text-center">{this.state.txtPage.txtFooter07}</h6>
													<h6 className="text-center">{this.state.txtPage.txtFooter08} <a href="mailto:support@pathbooks.app">support@pathbooks.app</a></h6>
												</div>
												<p><a href="#1" onClick={this.signout}>{this.state.txtPage.text13}</a></p>
											</div>
											: null
									}
									{
										this.state.mode === "recuperapass" ?
											<div>
												<fieldset>
													<br/>
													<h3 className="tituloNegro text-center">{this.state.txtPage.text20}</h3>
													<br/>
													<p>{this.state.txtPage.text21}</p>
													<br/>
													<div className="form-field">
														<input name="email" type="email" placeholder={this.state.txtPage.impText02} onChange={this.textChange} className="full-width form-control"/>
													</div>
													<div className="form-field">
														<br/>
														<button className="botonCompraAhora" onClick={this.restaurarPass}>{this.state.txtPage.textBtn04}</button>
													</div>
												</fieldset>
												<br/>

												<h6 className="tituloNegro text-center">
													{this.state.txtPage.text04}
													<a href="#1" onClick={() => this.setState({mode: "signin"})}>{this.state.txtPage.text05}</a> <br/>
												</h6>
											</div>
											: null
									}

									{
										this.state.mode === "picture" ?
											<div>
												<div>
													<br/>
													<h5>{this.state.txtPage.text12} {this.state.name}</h5>
													<p><a href="#1" onClick={this.signout}>{this.state.txtPage.text13}</a></p>
													<h3 className="text-center">{this.state.txtPage.text11}</h3>
													<div className="form-field">
														<p className="text-center">Barra de estado</p>
														<progress value={this.state.barraLoad1} max={this.state.barraLoadEnd1}/>
													</div>
													<br/>
													<div className="form-field">
														<div className="custom-file">
															<br/>
															<input type="file" accept="image/jpg" className="custom-file-input" onChange={this.cargaFotoAfiliado.bind(this)}/>
															<label className="custom-file-label" htmlFor="inputGroupFile02">Tipo .JPG</label>
														</div>
													</div>

												</div>
											</div>
											: null
									}
									{
										this.state.mode === "perfil" ?
											<div >
												<fieldset>
													<br/>
													<h3 className="tituloNegro text-center">{"Muchas gracias por registrarte " + this.state.afiliadoDate.name}</h3>
													<br/>
													<div className="container">
														<div className="row ">
															<img className="avatar center" src={this.state.afiliadoDate.picAfiliado} alt={"userfoto"}/>
														</div>
													</div>
													<br/>
													<h5 className="text-center">Felicidades tu codigo se genero con exito </h5>
													<div className="card-footer text-muted">
														<p>Codigo URL:</p>
														<h5 className="">{"https://read.pathbooks.app/af?rid=" + this.state.afiliadoDate.txtLink + "&divi=" + this.state.afiliadoDate.divisa}</h5>
														<CopyToClipboard text={"https://read.pathbooks.app/af?rid=" + this.state.afiliadoDate.txtLink + "&divi=" + this.state.afiliadoDate.divisa}>
															<button className="botonCompraAhora" onClick={this.linkcopiado}>Copiar link</button>
														</CopyToClipboard>
													</div>
													<br/>
													<div>
														<h3><a href={"https://read.pathbooks.app/af?rid=" + this.state.afiliadoDate.txtLink + "&divi=" + this.state.afiliadoDate.divisa} target="_blank" rel="noopener noreferrer">Ir a tu pagina web, clic aquí</a></h3>
													</div>


													<br/>
													<div className="">
														<h3 className="text-center">Verifica tus datos </h3>
														<h5 className="">{"Nombre: " + this.state.afiliadoDate.name}</h5>
														<h6 className="">{"Email: " + this.state.afiliadoDate.email}</h6>
														<h5 className="description-header">Oferta:</h5>
														<h5 className="">{this.state.afiliadoDate.title}</h5>
														<h6 className="description-header">Precio: $ {this.state.afiliadoDate.amount + " " + this.state.afiliadoDate.divisa + " - Días " + this.state.afiliadoDate.days}</h6>
													</div>

												</fieldset>

												<h3><a href="#1" onClick={this.signout}>{this.state.txtPage.text18}</a></h3>
											</div>
											: null
									}
								</div>
							</div>
						</div>

					</div>

				</section>

				<Preloader/>
				<footer className="footer">
					<div className="copy-right_text">
						<div className="container">
							<div className="row">
								<div className="col-xl-12">
									<br/>
									<p className="copy_right text-center">
										Copyright &copy;
										<script>document.write(new Date().getFullYear());</script>
										All rights reserved | Pathbooks
										<br/>

									</p>
								</div>
							</div>
						</div>
					</div>
				</footer>
			</div>
		);
	}
}


